import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Collapse,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box } from "@mui/system";

import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Link from "@mui/material/Link";
import TabPanel from "../Tabpanel";
import { useFetch } from "../../hooks/fetchData";
import { a11yProps } from "../Tabpanel";

import { IItemTab } from "../../models/list";

import { useAppSelector } from "src/hooks/hooks";
import { AppTestpreviewContext } from "src/context/appOpenTestpreviewContext";
import { getListData } from "src/services/localStorage";
import { AppGlobalContext } from "src/context/appGlobalContext";

interface IProps {
  urlForList: string;
  tabsData: IItemTab[];
}

const ItemInList = <
  ItemClass extends {
    _id?: string;
    id?: string;
    name: string;
    email?: string;
    group?: string;
    date?: string;
    createdAt?: string;
  },
>({
  urlForList,
  tabsData,
}: IProps) => {
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [editData, setEditData] = useState<any>({});
  const { darkMode, authData } = useAppSelector((state) => state);
  const navigate = useNavigate();

  const routerHistory = useRef(0);
  const { emitGlobalEvent, widthForRightSideBar, appForRightSideBar } =
    React.useContext(AppGlobalContext);
  const { setOpenTestpreviewData } = React.useContext(AppTestpreviewContext);
  const {
    setError,
    setSuccess,
    error,
    success,
    updateItem,
    fetchItem,

    item,
    setItem,
    addItem,
    deleteItem,
    loading,
    setLoading,
    reorderItems,
  } = useFetch<ItemClass>(`/api/${urlForList}`, { _id: id });

  const { toggleParangaForViewportTrue, toggleParangaForViewportFalse } =
    useContext(AppGlobalContext);

  const createNewItemText = useMemo(() => {
    switch (urlForList) {
      case "groups":
        return "Create a new Group";
      case "categories":
        return "Create a new Category";
      case "datasets":
        return "Create a new Dataset";
      case "workflows":
        return "Create a new Workflow";
      default:
        return "Create a new Item";
    }
    //eslint-disable-next-line
  }, []);

  let t = Number(searchParams.get("tab"));
  if (Number.isNaN(t) || typeof t === "undefined") {
    t = 0;
  }

  const [tabValue, setTabValue] = useState(t);
  const handleChangeTabValue = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setSearchParams((prev) => {
      return { ...prev, tab: newValue + "" };
    });
    //setSearchParams("tab=" + newValue);
  };
  function handleClickBreadcrumbs(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();

    // if (id === "newitem") {
    //   routerHistory.current = 2;
    // }

    navigate(-routerHistory.current);

    //navigate(`/${urlForList}`);
  }
  const onSubmit = async (
    data: Partial<ItemClass> | string,
    options: {
      model?: string;
      error?: any;
      skipParanga?: boolean;
      success?: boolean;
      redirect?: boolean;
    } = {},
  ) => {
    const { model, error: localError, skipParanga, success: localSuccess } = options;

    if (!skipParanga) {
      toggleParangaForViewportTrue();
    }

    if (typeof data === "string" && data === "delete") {
      const err = await deleteItem();
      if (!err) {
        emitGlobalEvent({
          event: "itemDeleted",
          data: { item: { _id: id }, type: urlForList },
        });
        setTimeout(() => {
          navigate(`/${urlForList}`);
        }, 100);
      }
    } else if (data === null) {
      //  only for using point for invoke setError or setSucess from components that changes keyForValue or valueforKey (onSubmit(null 0 in search)
      if (typeof localError === "object") {
        //console.log(localError);
        setError(localError.message);
        setTimeout(() => setError(""), 6000);
      } else if (localSuccess === true) {
        setSuccess("Saved");
        setTimeout(() => setSuccess(""), 4000);
      }
    } else if (typeof data === "object") {
      if (id === "newitem") {
        const res = await addItem(data);
        //console.log(res);
        if (res) {
          emitGlobalEvent({
            event: "itemAdd",
            data: { item: res, type: urlForList },
          });
          //After creating an entity, always go to the editing page of the created entity.
          let url: string;
          switch (urlForList) {
            case "datasets":
              url = `/${urlForList}/${res._id}?tab=1`;
              break;
            case "workflows":
              url = `/${urlForList}/${res._id}?tab=2`;
              break;
            case "groups":
              url = `/${urlForList}/${res._id}?tab=1`;
              emitGlobalEvent({ event: "newGroup", data: res });

              break;
            case "admins":
              url = `/${urlForList}/${res._id}?tab=0`;
              break;
            case "users":
              url = `/${urlForList}/${res._id}?tab=1`;
              break;
            case "endpoints":
              url = `/${urlForList}/${res._id}?tab=1`;
              break;

            default:
              url = `/${urlForList}`;
              break;
          }
          setTimeout(() => {
            navigate(url);
          }, 100);
        }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });

        if (!model) {
          const updatedItem = await updateItem({ ...data, _id: editData._id });
          //console.log(updatedItem);
          if (urlForList === "endpoints" && updatedItem) {
            setItem(updatedItem);
          }

          emitGlobalEvent({
            event: "itemUpdated",
            data: { item: updatedItem, type: urlForList },
          });
        }
      }
    }

    toggleParangaForViewportFalse();
  };

  useEffect(() => {
    if (id === "newitem") {
      //console.log("newItem");
      setLoading(false);
      setTabValue(0);
      setSearchParams((prev) => {
        return { ...prev, tab: "0" };
      });
      setEditData({});
      routerHistory.current++;
      if (urlForList === "endpoints" || urlForList === "datasets") {
        //When creating a new Dataset or Endpoint, automatically select a Group from the selected filter (if a filter is selected).
        const data = getListData();
        const group =
          data &&
          data[authData.user?._id || "nouser"] &&
          data[authData.user?._id || "nouser"][urlForList] &&
          data[authData.user?._id || "nouser"][urlForList].paginate &&
          data[authData.user?._id || "nouser"][urlForList].paginate.query &&
          data[authData.user?._id || "nouser"][urlForList].paginate.query.group
            ? data[authData.user?._id || "nouser"][urlForList].paginate.query.group
            : "";

        setItem({ group: group, name: "" } as ItemClass);
      }
    } else {
      if (!searchParams.get("tab")) {
        setSearchParams((prev) => {
          return { ...prev, tab: "0" };
        });
        routerHistory.current++;
      } else {
        let t = Number(searchParams.get("tab"));

        if (Number.isNaN(t) || t < 0 || t > 10) {
          t = 0;
          setSearchParams((prev) => {
            return { ...prev, tab: "0" };
          });
          routerHistory.current++;
        }

        setTabValue(t);
      }

      fetchItem();
    }
    //eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    routerHistory.current++;
    //eslint-disable-next-lin1
  }, [tabValue]);

  useEffect(() => {
    if (item && !item.date && typeof item.createdAt !== "undefined") {
      const dd = new Date(item.createdAt);
      item.date = dd.toLocaleString();
    } else if (item && item.date) {
      const dd = new Date(item.date);
      item.date = dd.toLocaleString();
    }
    setEditData(item);

    // eslint-disable-next-line
  }, [item]);
  //console.log(editData);

  useEffect(() => {
    let t = Number(searchParams.get("tab"));
    if (!Number.isNaN(t) && t >= 0 && t <= 10 && tabValue !== t) {
      setTabValue(t);
    }
    //eslint-disable-next-line
  }, [searchParams.get("tab")]);

  useEffect(() => {
    return () => {
      // close previvew request window
      setOpenTestpreviewData(null);
    };
    //eslint-disable-next-line
  }, []);

  //console.log(routerHistory.current);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      onClick={handleClickBreadcrumbs}
      sx={{ cursor: "pointer" }}
    >
      {urlForList.charAt(0).toUpperCase() + urlForList.slice(1)}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      component={"h3"}
      className="font-medium text-gray-500 mb-4 flex"
    >
      <span className="flex-1 break-all ">
        {" "}
        {id === "newitem"
          ? createNewItemText
          : item.name
          ? item.name
          : item?.email
          ? item?.email
          : item?.date
          ? item.date
          : "Loading..."}
      </span>
    </Typography>,
  ];

  return (
    <>
      <div className="relative">
        <Stack spacing={2}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>

        <Stack
          sx={{
            width: { xs: "93%", sm: "50%", md: "40%", lg: "30%" },
            right: appForRightSideBar ? `${60 + widthForRightSideBar}px` : "60px",
            top: { xs: "59px", sm: "59px", md: "59px", lg: "59px" },
            position: "fixed",
            zIndex: 1000,
          }}
        >
          <Collapse
            in={!!(error && error !== "")}
            onClick={() => {
              setError("");
            }}
          >
            <Alert severity="error" variant={darkMode === "dark" ? "filled" : undefined}>
              <AlertTitle>Error</AlertTitle>
              <strong>{error}</strong>
            </Alert>
          </Collapse>
          <Collapse
            in={!!(success && success !== "")}
            onClick={() => {
              setSuccess("");
            }}
          >
            {/* {darkMode === "dark" ? "text-green-100" : ""} */}
            <Alert severity="success" variant={darkMode === "dark" ? "filled" : undefined}>
              <AlertTitle>Success</AlertTitle>
              <strong>{success}</strong>
            </Alert>
          </Collapse>
        </Stack>

        {id !== "newitem" && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              variant="scrollable"
              onChange={handleChangeTabValue}
              aria-label="editing endpoint"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              {tabsData.map((tabData: IItemTab, i: number) => (
                <Tab label={tabData.label} {...a11yProps(0)} key={i} />
              ))}
            </Tabs>
          </Box>
        )}

        {tabsData.map((tabData: IItemTab, i: number) => {
          return (
            // (id === "newitem" || item._id) && (

            // )
            <TabPanel value={tabValue} index={i} key={i}>
              {tabData.component.map((Tabcomponent, ii: number) => {
                return (
                  <Tabcomponent
                    key={ii}
                    onSubmit={onSubmit}
                    initData={editData}
                    loading={loading}
                    reorderItems={reorderItems}
                  />
                );
              })}
            </TabPanel>
          );
        })}
      </div>
    </>
  );
};

export default ItemInList;
