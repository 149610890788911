import { useEffect, useState, useContext } from "react";
import "./login.scss";
import "./login.css";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../hooks/hooks";
import { authenticateUser } from "../../store/auth/auth.slice";
import { useAppDispatch } from "../../hooks/hooks";
import appData from "../../appdata.json";
import { AppCacheContext } from "src/context/appCacheContext";
export default function Login() {
  const navigate = useNavigate();
  const [err, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { authData } = useAppSelector((state) => state);
  const { clearCaches } = useContext(AppCacheContext);
  const { TITLE, VERSION } = appData;

  const today = new Date();
  const year = today.getFullYear();
  //console.log(today.getFullYear());
  const dispatch = useAppDispatch();

  const handleClick = async (e) => {
    e.preventDefault();
    clearCaches();
    dispatch(authenticateUser({ email: email, password: password }));
  };
  const handleResetPswd = async (e) => {
    e.preventDefault();
    console.log("resetpassword");
    return navigate("/resetpassword");
  };
  useEffect(() => {
    //console.log(authData.error);
    if (authData.error) {
      setError(true);
    }
  }, [authData.error]);

  useEffect(() => {
    if (authData.user) {
      navigate("/");
    }
  }, [authData.user, navigate]);

  return (
    <div className="login">
      <div className="loginLeft"></div>
      <div className="loginWrapper">
        <div className="loginTitle">
          <span>{TITLE} </span>
          <span> </span>
          <span>{VERSION}</span>
        </div>
        <div className="loginLogo">
          <LockOutlinedIcon className="loginIcon" />
        </div>
        <h3 className="loginTitle">Sign in</h3>

        <form className="loginBox" onSubmit={handleClick} autoComplete="off">
          <TextField
            label="Email"
            error={err}
            required
            variant="outlined"
            //type="email"
            margin="normal"
            onChange={(e) => {
              setEmail(e.target.value);
              setError(false);
            }}
          />
          <TextField
            label="Password"
            error={err}
            required
            type="password"
            variant="outlined"
            margin="normal"
            minLength="6"
            onChange={(e) => {
              setPassword(e.target.value);
              setError(false);
            }}
          />
          {err && <span className="spanError">Wrong Email or Password</span>}
          <Button type="submit" disabled={authData.isFetching} variant="contained" sx={{ mt: 2 }}>
            SIGN IN
          </Button>
          <span className="loginForgot" onClick={handleResetPswd}>
            Reset password?
          </span>

          <span className="loginCopyright">Copyright © {year} Bamboo Strategic Media.</span>
        </form>
      </div>
    </div>
  );
}
