import { Button, Grid, Card, CardContent, Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { TextField } from "formik-mui";
import { forceLogin } from "../../store/auth/auth.slice";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { IPropsItemInList } from "../../models/list";
import { IUser, UserClass } from "../../models/user";
import Appmodal from "../Appmodal";

import { AppCacheContext } from "src/context/appCacheContext";
import { AppGlobalContext } from "src/context/appGlobalContext";
//https://codesandbox.io/s/l2r832l8x7?file=/src/index.js
const dataSchema = yup.object({
  email: yup.string().max(100, "Must be 100 characters or less").required("Required"),
  password: yup
    .string()
    .required("Required")
    .min(12, "Password must be 12 characters long.")
    .max(50, "Must be 50 characters or less.")
    .matches(/[0-9]/, "Password must contain at least one number.")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/[^\w]/, "Password must contain at least one symbol."),
  confirmPassword: yup
    .string()
    .required("Required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("password")], "Passwords do not match."),
    }),
});

const UserPageContent = ({ onSubmit, initData, loading }: IPropsItemInList<IUser>) => {
  const [data, setData] = useState(new UserClass(initData));
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modalTitle, setModalTitle] = useState("");
  const [typeModal, setTypeModal] = useState("");
  const { toggleParangaForViewportTrue } = useContext(AppGlobalContext);
  const { authData } = useAppSelector((state) => state);
  const { clearCaches } = useContext(AppCacheContext);
  const handleCloseModal = () => setOpenModal(false);

  const handleSubmit = (dataForUpdate: UserClass) => {
    const { _id, password, email } = dataForUpdate;
    if (!_id) {
      setData(new UserClass({ email: email }));
      onSubmit({ password, email });
    } else {
      onSubmit({ _id, password });
    }
  };

  // const handleDeleteItem = (e: React.MouseEvent<HTMLElement>) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   setOpenModal(true);
  // };

  const handleOpenModal = (type: string) => {
    setTypeModal(type);
    if (!initData._id) {
      return;
    }
    switch (type) {
      case "login": {
        setModalTitle(`Login as ${initData?.email}?`);
        break;
      }
      case "delete": {
        setModalTitle(`Delete ${initData?.email}?`);
        break;
      }
      default:
        break;
    }

    setOpenModal(true);
  };

  const handleAction = async () => {
    try {
      if (typeModal === "login") {
        handleLogin();
      } else if (typeModal === "delete") {
        deleteItem();
      }
    } catch (err) {}
  };
  const handleLogin = async () => {
    try {
      if (!initData._id) {
        throw new Error("No id provided in initData");
      }
      toggleParangaForViewportTrue();
      clearCaches();
      dispatch(forceLogin(initData?._id));
      navigate("/");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 200);
    } catch (err) {}
  };
  const deleteItem = async () => {
    onSubmit("delete");
  };

  useEffect(() => {
    setData(new UserClass(initData));
    //eslint-disable-next-line
  }, [initData]);

  return (
    <div>
      <Appmodal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        setOpenModal={setOpenModal}
        modalTitle={modalTitle}
        handleAction={handleAction}
      />

      {loading && <p className="text-center">Loading...</p>}
      {!loading && (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={6} className="relative">
            <Card>
              <CardContent>
                <Formik
                  initialValues={data}
                  enableReinitialize={true}
                  validationSchema={dataSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    handleSubmit(values);
                  }}
                >
                  {({ submitForm, isSubmitting }) => (
                    <Form>
                      <Field
                        component={TextField}
                        name="email"
                        label="Email"
                        type="email"
                        required
                        disabled={!!initData._id}
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        onKeyDown={submitForm}
                      />
                      <Field
                        component={TextField}
                        sx={{ mt: 4 }}
                        fullWidth
                        required
                        name="password"
                        type="password"
                        label="Password"
                        autoComplete="off"
                        variant="standard"
                        //onKeyDown={submitForm}
                      />
                      <Field
                        component={TextField}
                        sx={{ mt: 4 }}
                        fullWidth
                        type="password"
                        required
                        name="confirmPassword"
                        label="Confirm Password"
                        autoComplete="off"
                        variant="standard"
                        //onKeyDown={submitForm}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: { xs: "flex-end", sm: "flex-start" },
                        }}
                      >
                        {authData &&
                          initData &&
                          authData.user &&
                          authData.user._id !== initData._id && (
                            <Grid item order={{ xs: 2, sm: 2 }}>
                              <Button
                                variant="outlined"
                                sx={{ mt: 3, ml: 1 }}
                                onClick={(e) => handleOpenModal("login")}
                                color="success"
                              >
                                login as
                              </Button>
                            </Grid>
                          )}
                        <Grid item order={{ xs: 3, sm: 1 }}>
                          <Button
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                            onClick={submitForm}
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </Grid>
                        <Grid item order={{ xs: 1, sm: 3 }}>
                          {initData && initData._id && (
                            <Button
                              variant="outlined"
                              sx={{ mt: 3, ml: 1 }}
                              onClick={(e) => handleOpenModal("delete")}
                              //onClick={handleDeleteItem}
                              color="error"
                            >
                              delete
                            </Button>
                          )}
                        </Grid>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default UserPageContent;
