import { RequireAdmin } from "./components/RequireAdmin";
import { Route, Routes } from "react-router-dom";
import List from "./components/list/List";

import ItemInList from "./components/list/ItemInList";

import NotFound from "./components/NotFound";
//data

import {
  initPaginateUser,
  userColumns,
  userTabsData,
} from "./components/users/dataForRouteComponent";
import {
  TranslationlogTabsData,
  translationlogColumns,
  translationlogTransformFunction,
  initPaginateTranslationlog,
} from "./components/translationlog/dataForRouteComponent";

import { TranslationlogClass } from "./models/translationlog";
import { UserClass } from "./models/user";

import Settings from "./components/setting/Settings";

const AppRouteSuperAdmin = () => {
  return (
    <Routes>
      <Route path="/users" element={<UsersList />}>
        <Route
          path="/users/:id"
          element={
            <RequireAdmin>
              <ItemInList<UserClass> urlForList="users" tabsData={userTabsData} />
            </RequireAdmin>
          }
        />
      </Route>
      <Route path="/settings" element={<Settings />}></Route>

      <Route path="/translationlogs" element={<Translationlogs />}>
        <Route
          path="/translationlogs/:id"
          element={
            <RequireAdmin>
              <ItemInList<TranslationlogClass>
                urlForList="translationlogs"
                tabsData={TranslationlogTabsData}
              />
            </RequireAdmin>
          }
        />
      </Route>
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouteSuperAdmin;

const UsersList = () => (
  <List<UserClass>
    listColumns={userColumns}
    urlForList="users"
    searchField={true}
    initPaginate={initPaginateUser}
  />
);

const Translationlogs = () => (
  <List<TranslationlogClass>
    listColumns={translationlogColumns}
    urlForList="translationlogs"
    searchField={true}
    initPaginate={initPaginateTranslationlog}
    transformFunction={translationlogTransformFunction}
  />
);
