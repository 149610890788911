import React, { useState, createContext } from "react";

//console.log(process.env);

export type RightSideAppType = "endpointsLogs" | "helpCenter" | "importSwagger";
export type GlobalEventType =
  | "importSwaggerSuccess"
  | "clearCahceImportSwagger"
  | "itemUpdated"
  | "itemDeleted"
  | "itemAdd"
  | "setEndpointInFilter"
  | "setGlobalGroup"
  | "setFilterDataForList"
  | "newGroup"
  | "reloadAddAccessGroupForUser";

export interface IEventsListAppForRightSideBar {
  event: "appForRightSideBar";
  data: RightSideAppType;
}

const defaultState = {
  emitGlobalEvent: (data: { event: GlobalEventType; data?: any }) => {},
  suscribeGlobalEvent: (event: GlobalEventType, handler: (data?: any) => void) => {},
  unsuscribeGlobalEvent: (event: GlobalEventType, handler: (data?: any) => void) => {},

  // for help
  pathHelpcenter: "",
  setPathHelpcenter: (v: string) => {},

  // rightbar app
  appForRightSideBar: null,
  setAppForRightSideBar: (v: string | null) => {},
  widthForRightSideBar: 380,
  setWidthForRightSideBar: (v: number) => {},

  parangaForViewport: false,
  toggleParangaForViewportFalse: () => {},
  toggleParangaForViewportTrue: () => {},
};
const globalEvents: any = {};

interface IHelpcenterContext {
  emitGlobalEvent: (data: { event: GlobalEventType; data?: any }) => void;
  suscribeGlobalEvent: (event: GlobalEventType, handler: (data?: any) => void) => void;
  unsuscribeGlobalEvent: (event: GlobalEventType, handler: (data?: any) => void) => void;

  pathHelpcenter: string;
  setPathHelpcenter: (v: string) => void;

  widthForRightSideBar: number;

  setWidthForRightSideBar: (v: number) => void;
  appForRightSideBar: string | null;
  setAppForRightSideBar: (v: string | null) => void;

  parangaForViewport: boolean;
  toggleParangaForViewportFalse: () => void;
  toggleParangaForViewportTrue: () => void;
}

interface Props {
  children: React.ReactNode;
}

export const AppGlobalContext = createContext<IHelpcenterContext>(defaultState);

export const AppGlobalContextProvider: React.FC<Props> = ({ children }) => {
  const [pathHelpcenter, setPathHelpcenter] = useState(defaultState.pathHelpcenter);

  const [appForRightSideBar, setAppForRightSideBar] = useState<null | string>(
    defaultState.appForRightSideBar,
  );

  const [widthForRightSideBar, setWidthForRightSideBar] = useState(
    defaultState.widthForRightSideBar,
  );

  const [parangaForViewport, setParangaForViewport] = useState(defaultState.parangaForViewport);
  const toggleParangaForViewportFalse = () => {
    setParangaForViewport(false);
  };
  const toggleParangaForViewportTrue = () => {
    setParangaForViewport(true);
  };
  const emitGlobalEvent = (data: { event: GlobalEventType; data?: any }) => {
    //console.log(data, globalEvents[data.event]);
    if (globalEvents[data.event] && globalEvents[data.event].length > 0) {
      globalEvents[data.event].forEach((handler: (data?: any) => void) => handler(data.data));
    }
  };
  const suscribeGlobalEvent = (event: GlobalEventType, handler: (data?: any) => void) => {
    //console.log(event);
    if (!globalEvents[event]) {
      globalEvents[event] = [];
    }
    globalEvents[event].push(handler);
  };
  const unsuscribeGlobalEvent = (event: GlobalEventType, handler: (data?: any) => void) => {
    //console.log(event);
    if (globalEvents[event] && globalEvents[event].length > 0) {
      const index = globalEvents[event].indexOf(handler);
      //console.log(index);
      if (index > -1) {
        globalEvents[event].splice(index, 1);
      }
    }
  };

  return (
    <AppGlobalContext.Provider
      value={{
        emitGlobalEvent,
        suscribeGlobalEvent,
        unsuscribeGlobalEvent,

        pathHelpcenter,
        setPathHelpcenter,
        appForRightSideBar,
        setAppForRightSideBar,
        widthForRightSideBar,
        setWidthForRightSideBar,
        parangaForViewport,
        toggleParangaForViewportFalse,
        toggleParangaForViewportTrue,
      }}
    >
      {children}
    </AppGlobalContext.Provider>
  );
};
