import React, { useState, createContext } from "react";

const defaultState = {
  caches: {},
  setCaches: (key: string, path: string, value: { paginate: any; items: any[] }) => {},
  getCaches: (key: string, path: string) => {
    return { paginate: {}, items: [] };
  },
  clearCaches: (key?: string) => {},
};

interface ICacheContext {
  caches: any;
  setCaches: (key: string, path: string, value: { paginate: any; items: any[] }) => void;
  getCaches: (key: string, path: string) => any;
  clearCaches: (key?: string) => void;
}
interface Props {
  children: React.ReactNode;
}

export const AppCacheContext = createContext<ICacheContext>(defaultState);

export const AppCacheContextProvider: React.FC<Props> = ({ children }) => {
  const [caches, setData] = useState<any>(defaultState.caches);

  const setCaches = (key: string, path: string, value: any) => {
    if (!caches[key]) {
      caches[key] = {};
    }
    caches[key][path] = value;
    setData({ ...caches });
  };
  const getCaches = (key: string, path: string) => {
    return caches[key] && caches[key][path];
  };
  const clearCaches = (key?: string) => {
    if (key) {
      delete caches[key];
      setData({ ...caches });
    } else {
      setData({});
    }
  };

  return (
    <AppCacheContext.Provider
      value={{
        caches,
        setCaches,
        getCaches,
        clearCaches,
      }}
    >
      {children}
    </AppCacheContext.Provider>
  );
};
