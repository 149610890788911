import { useAppSelector } from "../hooks/hooks";
import Login from "../pages/login/Login";
export const RequireAdmin = ({ children }: { children: React.ReactNode }) => {
  const { authData } = useAppSelector((state) => state);

  return (
    <>
      {authData.user && authData.user.roles.some((r: any) => r.role === "SuperAdmin") && children}{" "}
      {!authData.user && <Login />}
    </>
  );
};
