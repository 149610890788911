import { useEffect, useState } from "react";
import { ILang } from "./Settings";
import { Box, Typography } from "@mui/material";
import { useAppSelector } from "src/hooks/hooks";

const LangsList = ({ langs, code3, sx = {} }: { langs?: ILang[]; code3?: string[]; sx?: any }) => {
  //console.log(code3);
  const [items, setItems] = useState<ILang[]>([]);
  const { authData } = useAppSelector((state) => state);
  const headers = {
    "x-access-token": "",
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (authData.user) {
    headers["x-access-token"] = `Bearer ${authData.user.token}`;
  }

  const getLangData = async () => {
    try {
      const res = await fetch(`/assets/lang.json`, {
        method: "GET",
        headers,
      });
      if (res.status !== 200 && res.status !== 201) {
        throw await res.json();
      }
      const responseData = await res.json();
      if (code3 && code3.length) {
        setItems(
          code3.map((c) => {
            const lang = responseData.find((l: ILang) => l.code3 === c);
            //console.log(lang);
            if (lang) {
              return lang;
            } else {
              return { full: "?????" };
            }
          }),
        );
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (langs && langs.length > 0) {
      setItems(langs);
    } else if (code3 && code3.length > 0) {
      getLangData();
    }
    //eslint-disable-next-line
  }, [langs, code3]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
      <Typography variant="body2" sx={{ mr: "5px" }}>
        Selected languages:{" "}
      </Typography>
      {items.map((l, i) => (
        <Lang lang={l.full} key={l.code3} />
      ))}
    </Box>
  );
};

const Lang = ({ lang }: { lang: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        //py: "1px",
        px: "6px",
        borderRadius: "10px",
        border: "1px solid",
        mr: "5px",
        backgroundColor: "primary.light",
        borderColor: "primary.light",
        color: "background.default",
      }}
    >
      {lang}
    </Box>
  );
};

export default LangsList;
