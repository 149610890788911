import React, { useContext, useEffect, useMemo, useState } from "react";
import "./list.scss";
import { DataGrid } from "@mui/x-data-grid";
import {
  Alert,
  AlertTitle,
  Box,
  Breadcrumbs,
  Button,
  Collapse,
  Stack,
  TextField,
} from "@mui/material";

import LoopIcon from "@mui/icons-material/Loop";
import { Add } from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAppSelector } from "../../hooks/hooks";
import { useFetch } from "../../hooks/fetchData";
import { useLocation, Outlet, Link, useSearchParams } from "react-router-dom";

import { IPaginate } from "../../models/models";
import { useDebounce } from "../../hooks/debounce";
import { Link as Linkmui } from "@mui/material";
import { AppFilterContext } from "../../context/appFilterContext";

import { IParamsForColums } from "../../models/list";

import Appmodal from "../Appmodal";

import { getListData, setListData } from "src/services/localStorage";
import LangsList from "../setting/LangsList";
import { AppGlobalContext } from "src/context/appGlobalContext";
//import Sparkles, { StyledBtn, TransformDiv } from "../Sparkles";

interface IProps {
  urlForList: string;
  listColumns: ({ handleActionItem, setItems, user }: IParamsForColums) => any;
  initPaginate?: IPaginate;
  searchField?: boolean;
  listFilters?: string[];
  AdditionalComponent?: React.FC<any>;
  transformFunction?: (data: any) => any;
}

const List = <
  ListClass extends {
    _id?: string;
    id?: string;
    name: string;
    email?: string;
    index?: number;
  },
>({
  urlForList,
  listColumns,
  initPaginate,
  searchField,
  listFilters,
  AdditionalComponent,

  transformFunction,
}: IProps) => {
  //console.log("List");
  //console.log(crypto.randomUUID());
  const { widthForRightSideBar, appForRightSideBar, suscribeGlobalEvent, unsuscribeGlobalEvent } =
    useContext(AppGlobalContext);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentItem, setCurrentItem] = useState<Partial<ListClass>>({});

  const { authData, darkMode } = useAppSelector((state) => state);

  const searchParam = useMemo(() => {
    return searchParams.get("search") ? searchParams.get("search") || "" : "";
    //eslint-disable-next-line
  }, []);
  const [search, setSearch] = useState<string>(searchParam);
  const [action, setAction] = useState("");

  const debounced = useDebounce(search, 700);
  const user = authData.user || {};
  const { appFilterGroup } = useContext(AppFilterContext);

  const listFiltersForFilterComponent = useMemo(() => {
    return listFilters;
    //eslint-disable-next-line
  }, [listFilters]);
  const initPaginateForFetch = useMemo(() => {
    const initPaginateForFetch: IPaginate = initPaginate
      ? { ...initPaginate }
      : { currentPage: 1, perPage: 10, totalItems: 0, totalPages: 0 };
    if (
      listFiltersForFilterComponent &&
      listFiltersForFilterComponent.length > 0 &&
      appFilterGroup &&
      listFiltersForFilterComponent.indexOf("groups") > -1 &&
      initPaginate
    ) {
      initPaginateForFetch.query = {
        group: appFilterGroup,
      };
    } else {
      delete initPaginateForFetch?.query;
    }
    if (searchParam) {
      initPaginateForFetch.search = searchParam;
    }

    // Save the selected number of elements in the table on each page.
    const data = getListData();
    if (
      data &&
      data[authData.user?._id || "nouser"] &&
      data[authData.user?._id || "nouser"][urlForList] &&
      data[authData.user?._id || "nouser"][urlForList].paginate &&
      data[authData.user?._id || "nouser"][urlForList].paginate.perPage
    ) {
      initPaginateForFetch.perPage =
        data[authData.user?._id || "nouser"][urlForList].paginate.perPage;
    }

    return initPaginateForFetch;
    //eslint-disable-next-line
  }, [listFiltersForFilterComponent, initPaginate]);

  const {
    fetchItems,

    cloneItem,
    loading,

    error,
    setError,
    success,
    setSuccess,

    items,
    setItems,
    deleteItem,
    setPaginate,
    reorderItems,
    paginate,
  } = useFetch<ListClass>(`/api/${urlForList}`, {
    initPaginate: initPaginateForFetch,
    transformFunction,
  });
  const { toggleParangaForViewportTrue, toggleParangaForViewportFalse } =
    useContext(AppGlobalContext);
  const [openModal, setOpenModal] = useState(false);

  //console.log(loading);
  const handleAction = () => {
    switch (action) {
      case "Delete":
        setAction("");
        deleteItemInComponent();
        break;
      case "Clone":
        handleCloneItem();
        setAction("");
        break;
      default:
        setAction("");
        break;
    }
  };
  const handleCloneItem = async () => {
    if (!currentItem._id) {
      return;
    }
    try {
      await cloneItem(currentItem._id);
      const tempPagination = Object.assign({}, paginate);
      tempPagination.search = "";
      tempPagination.loaded = false;
      tempPagination.currentPage = 1;
      setPaginate(tempPagination);
    } catch (err: any) {
      console.log(err);
    }
  };
  const deleteItemInComponent = async () => {
    if (!currentItem._id) {
      return;
    }
    toggleParangaForViewportTrue();
    const err = await deleteItem(currentItem._id);
    toggleParangaForViewportFalse();
    console.log(err);
    setCurrentItem({});
    if (!err) {
      fetchItems();
    }
  };

  const handleActionItem = (_id: string, action: string, options?: any) => {
    const item = items.find((p) => p._id === _id);
    if (item) {
      setCurrentItem(item);
      if (action === "reorder") {
        reorderItems(_id, options);
      } else {
        setAction(action);

        setOpenModal(true);
      }
    }
  };

  const columns = listColumns({ handleActionItem, setItems, user });
  const handleCloseModal = () => setOpenModal(false);

  const handleSuscribeImportSwaggerSuccess = (data: string) => {
    setSuccess(data);
    setTimeout(() => {
      setSuccess("");
    }, 5000);
  };
  // const handleSuscribeReloadItemInList = (data: { urlForList: string; item: ListClass }) => {
  //   //console.log(data);
  //   if (data && data.urlForList && data.urlForList === urlForList && data.item && data.item._id) {
  //     setItems((prev: ListClass[]) => [
  //       ...prev.map((p: ListClass) => {
  //         if (p && p._id === data.item._id) {
  //           return { ...p, ...data.item };
  //         }
  //         return p;
  //       }),
  //     ]);
  //   }
  // };

  const handleSuscribeItemUpdated = (data: { item: any; type: string }) => {
    if (data && data.item && data.item._id) {
      if (data.type && data.type === urlForList) {
        setItems((prev: ListClass[]) => [
          ...prev.map((p: ListClass) => {
            if (p && p._id === data.item._id) {
              return { ...p, ...data.item };
            }
            return p;
          }),
        ]);
      }
    }
  };
  const handleSuscribeItemDeleted = (data: { item: any; type: string }) => {
    if (data && data.item && data.item._id) {
      if (data.type && data.type === urlForList) {
        fetchItems();
      }
    }
  };
  const handleSuscribeItemAdd = (data: { item: any; type: string }) => {
    if (data && data.item && data.item._id && data.type && data.type === urlForList) {
      fetchItems();
    }
  };

  // after reload need to update current item
  useEffect(() => {
    if (currentItem && currentItem._id) {
      const newItem = items.find((item) => item._id === currentItem._id);
      if (newItem) {
        setCurrentItem(newItem);
      }
    }
    //eslint-disable-next-line
  }, [items]);

  //  reload list if  seach string was typed
  useEffect(() => {
    //console.log("useEffect debounced");
    if (paginate.search === debounced) {
      return;
    }
    if (debounced) {
      searchParams.set("search", debounced);
      setSearchParams(searchParams);
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }

    const tempPagination = Object.assign({}, paginate);
    tempPagination.search = debounced;
    tempPagination.loaded = false;
    setPaginate(tempPagination);
    //fetchItems();
    //eslint-disable-next-line
  }, [debounced]);
  // save in localstorage
  useEffect(() => {
    setListData(authData.user?._id || "nouser", urlForList, paginate);
    //eslint-disable-next-line
  }, [paginate]);

  useEffect(() => {
    if (searchParams.get("search") === null) {
      setSearch("");
    }
    //eslint-disable-next-line
  }, [searchParams.get("search")]);
  useEffect(() => {
    suscribeGlobalEvent("importSwaggerSuccess", handleSuscribeImportSwaggerSuccess);
    suscribeGlobalEvent("itemUpdated", handleSuscribeItemUpdated);
    suscribeGlobalEvent("itemDeleted", handleSuscribeItemDeleted);
    suscribeGlobalEvent("itemAdd", handleSuscribeItemAdd);

    return () => {
      unsuscribeGlobalEvent("importSwaggerSuccess", handleSuscribeImportSwaggerSuccess);
      unsuscribeGlobalEvent("itemUpdated", handleSuscribeItemUpdated);
      unsuscribeGlobalEvent("itemDeleted", handleSuscribeItemDeleted);
      unsuscribeGlobalEvent("itemAdd", handleSuscribeItemAdd);
    };
    //eslint-disable-next-line
  }, []);

  const breadcrumbs = [
    <Linkmui underline="hover" key="2" color="inherit" sx={{ cursor: "pointer" }}>
      {urlForList.charAt(0).toUpperCase() + urlForList.slice(1)}
    </Linkmui>,
  ];

  return (
    <>
      <Outlet />
      <Appmodal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        setOpenModal={setOpenModal}
        modalTitle={`${action} ${currentItem?.name}?`}
        handleAction={handleAction}
        additionalComfirm={urlForList === "admins"}
        additionalTitle={`To delete the admin <strong>${currentItem?.email}</strong>, type the email to confirm.`}
        additionalPlaceholder="Enter email"
        adiitionalCheckValue={currentItem?.email}
      />

      <div className={location.pathname === `/${urlForList}` ? "itemsList" : "itemsList hidden"}>
        <Stack
          sx={{
            width: { xs: "93%", sm: "50%", md: "40%", lg: "30%" },
            right: appForRightSideBar ? `${60 + widthForRightSideBar}px` : "60px",

            top: { xs: "59px", sm: "59px", md: "59px", lg: "59px" },
          }}
          className="fixed    z-10"
        >
          <Collapse
            in={!!(error && error !== "")}
            onClick={() => {
              setError("");
            }}
          >
            <Alert severity="error" variant={darkMode === "dark" ? "filled" : undefined}>
              <AlertTitle>Error</AlertTitle>
              <strong>{error}</strong>
            </Alert>
          </Collapse>
          <Collapse
            in={!!(success && success !== "")}
            onClick={() => {
              setSuccess("");
            }}
          >
            <Alert severity="success" variant={darkMode === "dark" ? "filled" : undefined}>
              <AlertTitle>Success</AlertTitle>
              <strong>{success}</strong>
            </Alert>
          </Collapse>
        </Stack>

        <div className="listWrapper">
          <div className="top">
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Stack>
                <Breadcrumbs
                  sx={{ mb: 2 }}
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon fontSize="small" />}
                >
                  {breadcrumbs}
                </Breadcrumbs>
                {/* <Sparkles>
                    <TransformDiv>{breadcrumbs}</TransformDiv>
                  </Sparkles>*/}
                {/* <StyledBtn>Hello Word</StyledBtn> */}
              </Stack>

              {urlForList === "translationlogs" && (
                <div>
                  <Button
                    sx={{ position: "relative" }}
                    onClick={() => {
                      fetchItems();
                    }}
                    className=""
                    startIcon={<LoopIcon />}
                    size="small"
                    variant="outlined"
                    color="primary"
                  >
                    refresh list
                  </Button>
                </div>
              )}
            </Box>

            {typeof AdditionalComponent !== "undefined" && (
              <AdditionalComponent setPaginate={setPaginate} paginate={paginate} />
            )}

            <div className="addButtonSearchWrapper">
              {((authData.user &&
                authData.user.roles &&
                authData.user.roles.some(
                  (r: any) => r.role === "SuperAdmin" || r.role === "Admin",
                )) ||
                urlForList === "datasets") &&
                urlForList !== "translationlogs" && (
                  <div>
                    <Link to={`/${urlForList}/newitem?tab=0`}>
                      <Button
                        sx={{ position: "relative" }}
                        className=""
                        size="small"
                        startIcon={<Add />}
                        variant="outlined"
                        color="primary"
                      >
                        new
                      </Button>
                    </Link>
                  </div>
                )}

              {searchField && (
                <TextField
                  type="text"
                  size="small"
                  inputProps={{
                    style: {
                      height: 31,
                      padding: "0 14px",
                      width: "350px",
                      paddingLeft: "16px",
                    },
                  }}
                  placeholder={`Search...`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
            </div>

            {urlForList === "translationlogs" &&
              authData.user &&
              authData.user.settings &&
              authData.user.settings.languages && (
                <LangsList langs={authData.user.settings.languages} sx={{ mt: 1 }} />
              )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt:
                  listFiltersForFilterComponent && listFiltersForFilterComponent.length > 0 ? 2 : 1,
              }}
            ></Box>
          </div>

          {loading && (!items || !items.length) && <p className="text-center mt-8">Loading...</p>}
          {!loading && items && items.length === 0 && (
            <p className="text-center  mt-8">No results</p>
          )}

          {items && items.length > 0 && location.pathname === `/${urlForList}` && (
            <DataGrid
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,&.MuiDataGrid-root .MuiDataGrid-cell:focus":
                  {
                    outline: "none",
                  },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
              }}
              getRowHeight={() => "auto"}
              disableColumnSelector
              disableColumnFilter
              loading={loading}
              pagination
              paginationMode="server"
              rowCount={paginate?.totalItems}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              page={paginate?.currentPage ? paginate?.currentPage - 1 : 0}
              pageSize={paginate?.perPage}
              onPageChange={(data: number) => {
                const tempPagination = Object.assign({}, paginate);
                tempPagination.currentPage = data + 1;
                tempPagination.loaded = false;
                setPaginate(tempPagination);
              }}
              onPageSizeChange={(data: number) => {
                const tempPagination = Object.assign({}, paginate);
                tempPagination.currentPage = 1;
                tempPagination.perPage = data;
                tempPagination.loaded = false;
                setPaginate(tempPagination);
              }}
              className="datagrid mt-4 datagridInList"
              rows={items}
              columns={columns}
              disableSelectionOnClick
              checkboxSelection={false}
              autoHeight={true}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default List;
