import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { store } from "./store";
import { Provider } from "react-redux";
import { AppGlobalContextProvider } from "./context/appGlobalContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AppGlobalContextProvider>
        <App />
      </AppGlobalContextProvider>
    </BrowserRouter>
  </Provider>,
);
