import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IUserKeys } from "../../models/user";
import { ServerResponse } from "../../models/models";
import { RootState } from "../index";

export const userkeysApi = createApi({
  reducerPath: "users/userkeys",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/userkeys",
    prepareHeaders: (headers, { getState }) => {
      const user = (getState() as RootState).authData.user;
      //console.log(user);
      //If we have a token set in state, let's assume that we should be passing it.
      if (user && user.token) {
        headers.set("x-access-token", `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  refetchOnFocus: true,
  tagTypes: ["Userkeys"],
  endpoints: (build) => ({
    getUserKeys: build.query<
      ServerResponse<IUserKeys>,
      { search?: string; currentPage?: number; perPage?: number; condition?: string }
    >({
      query: ({ search, currentPage, perPage, condition }) => ({
        url: ``,
        params: {
          search: search,
          perPage: perPage,
          page: currentPage,
          condition: condition,
        },
      }),
      providesTags: ["Userkeys"],
      transformResponse: (response: ServerResponse<IUserKeys>) => {
        if (response && response.items && response.items.length > 0) {
          response.items = response.items.map((d, i) => {
            // d.id = i + 1 + response.perPage * (response.currentPage - 1);
            return d;
          });
        }
        return response;
      },
    }),

    addUserKeys: build.mutation<IUserKeys, Partial<IUserKeys>>({
      query(body: Partial<IUserKeys>) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created post could show up in any lists.
      //invalidatesTags: [{ type: 'Post', id: 'LIST' }],
      invalidatesTags: ["Userkeys"],
    }),
    updateUserKeys: build.mutation<IUserKeys, Partial<IUserKeys>>({
      query(data) {
        console.log(data);
        const { _id, id, ...body } = data;
        return {
          url: `/${_id}`,
          method: "PUT",
          body,
        };
      },

      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under its results.
      invalidatesTags: ["Userkeys"],

      //invalidatesTags: (result, error, { _id }) => [{ type: "Users", _id }],
    }),
    deleteUserKeys: build.mutation<{ success: boolean; _id: string }, string>({
      query(_id) {
        return {
          url: `/${_id}`,
          method: "DELETE",
        };
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      //invalidatesTags: (result, error, _id) => [{ type: "Users", _id }],
      invalidatesTags: ["Userkeys"],
    }),
  }),
});

export const {
  useGetUserKeysQuery,
  useAddUserKeysMutation,
  useDeleteUserKeysMutation,
  useUpdateUserKeysMutation,
} = userkeysApi;
