import React, { useState, createContext } from "react";
import {
  getAppFilterGroupFromStorage,
  setAppFilterGroupToStorage,
  getAppFilterCategoryFromStorage,
  setAppFilterCategoryToStorage,
} from "../services/localStorage";

const defaultState = {
  appFilterGroup: getAppFilterGroupFromStorage() || "",
  appFilterCategory: getAppFilterCategoryFromStorage() || "",
  toggleGroup: (s: string) => {},
  toggleCategory: (s: string) => {},
};

interface IAppFilterContext {
  appFilterGroup: string;
  appFilterCategory: string;
  toggleGroup: (s: string) => void;
  toggleCategory: (s: string) => void;
}
interface Props {
  children: React.ReactNode;
}

export const AppFilterContext = createContext<IAppFilterContext>(defaultState);

export const AppFilterContextProvider: React.FC<Props> = ({ children }) => {
  const [appFilterGroup, setAppFilterGroup] = useState(defaultState.appFilterGroup);
  const [appFilterCategory, setAppFilterCategory] = useState(defaultState.appFilterCategory);

  const toggleGroup = (s: string) => {
    setAppFilterGroup(s);
    setAppFilterGroupToStorage(s);
  };
  const toggleCategory = (s: string) => {
    setAppFilterCategory(s);
    setAppFilterCategoryToStorage(s);
  };

  return (
    <AppFilterContext.Provider
      value={{
        appFilterGroup,
        appFilterCategory,
        toggleGroup,
        toggleCategory,
      }}
    >
      {children}
    </AppFilterContext.Provider>
  );
};
