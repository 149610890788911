import "./style/dark.scss";
import "./style/app.scss";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useAppSelector } from "./hooks/hooks";
import { useActions } from "./hooks/actions";
import useWindowDimensions from "./hooks/windowDimensions";

import { RequireAuth } from "./components/RequireAuth";

import Home from "./pages/Home";
import Login from "./pages/login/Login";
import Resetpassword from "./pages/resetpassword/Resetpassword";

//context

import { AppFilterContextProvider } from "./context/appFilterContext";
import { AppCacheContextProvider } from "./context/appCacheContext";

import darkScrollbar from "@mui/material/darkScrollbar";
import AppRouteSuperAdmin from "./AppRouteSuperAdmin";

import AppRouteUser from "./AppRouteUser";
import Resetpasswordform from "./pages/resetpasswordform/Resetpasswordform";
import { AppTestpreviewContextProvider } from "./context/appOpenTestpreviewContext";
import { Box } from "@mui/material";

declare module "@mui/material/styles" {
  // interface Theme {
  //   status: {
  //     danger: React.CSSProperties["color"];
  //   };
  // }

  // interface Palette {
  //   neutral: Palette["primary"];
  // }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
    addColor: PaletteOptions["primary"];
  }
}
function App() {
  const { darkMode, authData } = useAppSelector((state) => state);

  const { windowWidth } = useWindowDimensions();
  const { closeSideBar, loguotAuth, updateAuth } = useActions();
  const location = useLocation();
  const navigate = useNavigate();
  const [classes, setClasses] = useState<string[]>([]);

  let reloadTimer: any;
  window.onblur = function () {
    reloadTimer = Date.now();
    window.onfocus = function () {
      const currentTime = Date.now();
      if (currentTime - reloadTimer > 600 * 1000) {
        window.location.reload();
      }
    };
  };
  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode: darkMode === "dark" ? "dark" : "light",
        primary: {
          main: "#5F35AE",
          dark: "#452382",
          light: "#814DDE",
        },
        neutral: {
          main: "#64748B",
          contrastText: "#fff",
        },
        addColor: {
          main: "#F6BE00",
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: (themeParam) => {
            return {
              body: darkMode === "dark" ? darkScrollbar() : null,
            };
          },
        },
      },
    });
  }, [darkMode]);

  useEffect(() => {
    setTimeout(reNewDataForUser, 1000);

    async function reNewDataForUser() {
      if (!authData.user) {
        return;
      }
      try {
        const res = await axios.get(`/api/users/${authData.user?._id}`, {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": `Bearer ${authData.user?.token}`,
          },
        });
        if (!res) {
          loguotAuth();
          navigate("/login");
        }
        const updatedUser = {
          ...authData.user,
          settings: res.data.settings,
          roles: res.data.roles,
        };
        if (updatedUser) {
          updateAuth(updatedUser);
        }
      } catch (err) {
        console.log(err);
        loguotAuth();
        navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    //console.log(location);
    if (windowWidth < 800) {
      closeSideBar();
    }
    //console.log(location.pathname);
    if (location.pathname === "/" && authData.user) {
      navigate("/translationlogs");
    } else if (
      !authData.user &&
      location.pathname !== "/resetpassword" &&
      location.pathname.indexOf("/resetpasswordform/") < 0
    ) {
      navigate("/login");
    } else if (
      (location.pathname === "/login" || location.pathname === "/resetpassword") &&
      authData.user
    ) {
      navigate("/translationlogs");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, authData.user]);

  useEffect(() => {
    const arr = ["app"];
    if (windowWidth < 800) {
      arr.push("modal");
    }
    if (darkMode === "dark") {
      arr.push("dark");
    }
    setClasses(arr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, darkMode]);

  //console.log(authData.user);

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppTestpreviewContextProvider>
          <AppCacheContextProvider>
            <AppFilterContextProvider>
              <Box
                className={classes.join(" ")}
                sx={{ backgroundColor: "background.default", color: "text.secondary" }}
              >
                <Routes>
                  <Route path="login" element={<Login />} />
                  <Route path="resetpassword" element={<Resetpassword />} />
                  <Route path="resetpasswordform/:key" element={<Resetpasswordform />} />
                  {authData.user && (
                    <Route
                      path="/"
                      element={
                        <RequireAuth>
                          <Home />
                        </RequireAuth>
                      }
                    >
                      {authData.user &&
                        authData.user.roles.some((r: any) => r.role === "SuperAdmin") && (
                          <Route path="/*" element={<AppRouteSuperAdmin />} />
                        )}
                      {authData.user && authData.user.roles.some((r: any) => r.role === "User") && (
                        <Route path="/*" element={<AppRouteUser />} />
                      )}
                    </Route>
                  )}
                </Routes>
              </Box>
            </AppFilterContextProvider>
          </AppCacheContextProvider>
        </AppTestpreviewContextProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
