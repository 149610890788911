import { Button } from "@mui/material";
import "./notFound.scss";
import { Link } from "react-router-dom";
var stylingObject = {
  div: {
    color: "red",
    display: "flex",
    justifyContent: "center",

    alignItems: "center",
  },
  h1: {
    fontSize: "200px",
  },
  h3: { fontSize: "28px" },
  h4: { fontSize: "24px" },
};
const NotFound = () => {
  return (
    <div style={stylingObject.div} className="notFound flex-col">
      <h1 style={stylingObject.h1}>404</h1>
      <h3 style={stylingObject.h3}>Page not found.</h3>
      <h4 style={stylingObject.h4}>
        <Link to="/">
          <Button variant="outlined" sx={{ mt: 3, ml: 1 }}>
            Return to the main page
          </Button>
        </Link>
      </h4>
    </div>
  );
};
export default NotFound;
