import { createSlice } from "@reduxjs/toolkit";
const LSPath = "theme";
const INIT_STATE: string = localStorage.getItem(LSPath) ?? "light";

export const darkModeSlice = createSlice({
  name: "darkMode",
  initialState: INIT_STATE,
  reducers: {
    light: (state) => {
      state = "light";
      localStorage.setItem(LSPath, state);
      return state;
    },
    dark: (state) => {
      state = "dark";
      localStorage.setItem(LSPath, state);
      return state;
    },
    toggleDarkMode: (state) => {
      if (state === "light") {
        state = "dark";
      } else {
        state = "light";
      }
      localStorage.setItem(LSPath, state);
      return state;
    },
  },
});

export const darkModeActions = darkModeSlice.actions;

export const { light, dark, toggleDarkMode } = darkModeSlice.actions;
export default darkModeSlice.reducer;
