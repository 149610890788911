import { Route, Routes } from "react-router-dom";
import List from "./components/list/List";

import ItemInList from "./components/list/ItemInList";

import NotFound from "./components/NotFound";
import { TranslationlogClass } from "./models/translationlog";
import {
  TranslationlogTabsData,
  translationlogColumns,
  translationlogTransformFunction,
  initPaginateTranslationlog,
} from "./components/translationlog/dataForRouteComponent";
//data

const AppRouteUser = () => (
  <Routes>
    <Route path="/translationlogs" element={<Translationlogs />}>
      <Route
        path="/translationlogs/:id"
        element={
          <ItemInList<TranslationlogClass>
            urlForList="translationlogs"
            tabsData={TranslationlogTabsData}
          />
        }
      />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRouteUser;
const Translationlogs = () => (
  <List<TranslationlogClass>
    listColumns={translationlogColumns}
    urlForList="translationlogs"
    searchField={true}
    initPaginate={initPaginateTranslationlog}
    transformFunction={translationlogTransformFunction}
  />
);
