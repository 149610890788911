import React, { useState, createContext } from "react";
import appData from "../appdata.json";
const LSPath = (appData.STORAGE || "ls") + "-TestPreviewHeight";
const INIT_STATE: number = JSON.parse(localStorage.getItem(LSPath) ?? "300");
//console.log("window.innerHeight", window.innerHeight);

const defaultState = {
  openTestpreviewData: null,
  openTestpreviewTop:
    window.innerHeight - INIT_STATE > 100
      ? window.innerHeight - INIT_STATE
      : window.innerHeight - 300,
  testDataMutation: false,
  setOpenTestpreviewData: (v: any) => {},
  setTestDataMutation: (v: boolean) => {},
  setOpenTestpreviewHeight: (v: number) => {},
  collapseValues: { headersCollapse: true, paramsCollapse: true, bodyCollapse: true },
  setCollapseValues: (v: any) => {},
  isResizing: false,
  setIsResizing: (v: boolean) => {},
};
//console.log(defaultState.openTestpreviewTop);

interface IContext {
  openTestpreviewData: any;
  testDataMutation: boolean;
  openTestpreviewTop: number;
  setOpenTestpreviewHeight: (v: number) => void;
  setOpenTestpreviewData: (v: any) => void;
  setTestDataMutation: (v: boolean) => void;
  collapseValues: any;
  setCollapseValues: (v: any) => void;
  isResizing: boolean;
  setIsResizing: (v: boolean) => void;
}

interface Props {
  children: React.ReactNode;
}

export const AppTestpreviewContext = createContext<IContext>(defaultState);

export const AppTestpreviewContextProvider: React.FC<Props> = ({ children }) => {
  const [isResizing, setIsResizing] = useState(defaultState.isResizing);
  const [openTestpreviewData, setOpenTestpreviewData] = useState(defaultState.openTestpreviewData);
  const [collapseValues, setCollapseValues] = useState(defaultState.collapseValues);
  const [testDataMutation, setTestDataMutation] = useState(defaultState.testDataMutation);
  const [openTestpreviewTop, setOpenTestpreviewHeightState] = useState(
    defaultState.openTestpreviewTop,
  );

  const setOpenTestpreviewHeight = (value: number) => {
    //console.log(window.innerHeight - value);
    localStorage.setItem(LSPath, JSON.stringify(window.innerHeight - value));
    setOpenTestpreviewHeightState(value);
  };

  return (
    <AppTestpreviewContext.Provider
      value={{
        isResizing,
        setIsResizing,
        openTestpreviewData,
        setOpenTestpreviewData,
        openTestpreviewTop,
        setOpenTestpreviewHeight,
        setTestDataMutation,
        testDataMutation,
        collapseValues,
        setCollapseValues,
      }}
    >
      {children}
    </AppTestpreviewContext.Provider>
  );
};
