import { Box, Divider, TextField } from "@mui/material";
import { useState } from "react";
//import { useAppSelector } from "src/hooks/hooks";

interface IProps {
  additionalTitle?: string;
  additionalPlaceholder?: string;
  adiitionalCheckValue?: string;
  setCheckData: (d: boolean) => void;
}

const AdditionalConfirmComponent = ({
  additionalTitle,
  additionalPlaceholder,
  adiitionalCheckValue,
  setCheckData,
}: IProps) => {
  //const { darkMode } = useAppSelector((state) => state);

  const [data, setData] = useState("");

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    let value = e.target.value;
    if (value === adiitionalCheckValue) {
      setCheckData(true);
    } else {
      setCheckData(false);
    }
    setData(value);
  };

  return (
    <Box>
      <Divider sx={{ my: 2 }} />

      <Box
        color="textSecondary"
        sx={{
          fontSize: "14px",
          ml: 1,
          mb: "4px",
        }}
        dangerouslySetInnerHTML={{ __html: additionalTitle || "" }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 1,
        }}
      >
        <TextField
          sx={{
            mx: 1,
            width: "100%",

            "& input": {
              padding: "6px",
            },
          }}
          id="additionalComfirmValue"
          size="small"
          placeholder={additionalPlaceholder}
          value={data}
          onChange={handleInput}
          variant="outlined"
          autoComplete="off"
        ></TextField>
      </Box>
      <Divider sx={{ mt: 3 }} />
    </Box>
  );
};

export default AdditionalConfirmComponent;
