import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { loguotAuth, updateAuth } from "../store/auth/auth.slice";

import { sideBarActions } from "../store/sideBar/sideBarSlice";
import { darkModeActions } from "../store/darkMode/darkModeSlice";
import { actionResultActions } from "../store/actionResults/actionResultSlice";

const actions = {
  ...sideBarActions,
  ...darkModeActions,
  ...actionResultActions,
  loguotAuth,
  updateAuth,
};

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};
