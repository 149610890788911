import { Box } from "@mui/material";
import { useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import "./prettyJson.scss";

const JsonToString = ({
  data,
  shift,
  comma,
  isObject,
}: {
  data: any;
  shift: number;
  comma?: boolean;
  isObject?: boolean;
}) => {
  //console.log(data);
  const items = isObject && data ? Object.keys(data) : data ? data : [];
  return (
    <>
      {!isObject && <span>{`[`}</span>}
      <Box
        sx={{
          ml: `${shift * 10}px`,
        }}
      >
        {isObject && <Box>{`{`}</Box>}
        {items.map((item: any, index: number) => {
          const element = isObject ? data[item] : item;
          return (
            <Box key={index}>
              {isObject && (
                <>
                  <span className="key">"{item}"</span>
                  <span className="">{":"}</span>
                  <span className=""> </span>
                </>
              )}
              {Array.isArray(element) && (
                <JsonToString
                  data={element}
                  shift={shift + (isObject ? 1 : 0)}
                  comma={index !== items.length - 1}
                />
              )}
              {typeof element === "object" && !Array.isArray(element) && element !== null && (
                <JsonToString
                  data={element}
                  shift={shift + (isObject ? 1 : 0)}
                  comma={index !== items.length - 1}
                  isObject={true}
                />
              )}
              {((typeof element !== "object" && !Array.isArray(element)) || element === null) && (
                <PrintValue data={element} comma={index !== items.length - 1} />
              )}
            </Box>
          );
        })}
        {isObject && <Box>{comma ? `},` : `}`}</Box>}
      </Box>
      {!isObject && <span style={{ marginLeft: `${shift * 10}px` }}>{comma ? `],` : `]`}</span>}
    </>
  );
};

const PrintValue = ({ data, comma }: { data: any; comma: boolean }) => {
  return (
    <>
      <span className="value">
        <span
          className={
            typeof data === "string" ? "string" : typeof data === "number" ? "number" : "boolean"
          }
        >
          {typeof data === "boolean"
            ? JSON.stringify(data)
            : typeof data === "number"
            ? data
            : `"${data}"`}
        </span>
      </span>
      {comma && <span className="">{","}</span>}
    </>
  );
};

export const PrettyDeepJSON = ({
  data,
  copyToClipboard,
}: {
  data: any;
  copyToClipboard?: boolean;
}) => {
  const [text, setText] = useState("Copy");

  const handleClick = () => {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    setText("Copied");
    setTimeout(() => {
      setText("Copy");
    }, 2000);
  };
  return (
    <pre style={{ fontSize: "12px" }}>
      <Box className="codeSnippet">
        {copyToClipboard && (
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              border: "0.5px solid",
              borderRadius: "5px",
              width: "85px",
              height: "28px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
              bgcolor: "action.disabledBackground",
              borderColor: "action.selected",
              color: "text.secondary",
              "&:hover": { bgcolor: "action.disabledBackground", color: "text.primary" },
            }}
            onClick={() => handleClick()}
          >
            <span>{text}</span>
            {text === "Copy" ? (
              <ContentCopyOutlinedIcon fontSize="small" />
            ) : (
              <DoneOutlinedIcon fontSize="small" />
            )}
          </Box>
        )}

        <JsonToString data={data} shift={0} isObject={!Array.isArray(data)} />
      </Box>
    </pre>
  );
};
