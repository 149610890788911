import { useState } from "react";
import "./resetpassword.scss";
import "./resetpassword.css";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";

import appData from "../../appdata.json";
export default function Resetpassword() {
  const { TITLE, VERSION } = appData;
  const today = new Date();
  const year = today.getFullYear();

  const [err, setError] = useState(false);
  const [errMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [disabledButton, seDisabledButtont] = useState(false);

  const [loginForgotMessage, setLoginForgotMessage] = useState(false);

  const handleResetPswd = async (e) => {
    e.preventDefault();
    seDisabledButtont(true);
    setErrorMsg("");
    try {
      await axios.post("/api/auth/reset-password", { email: email });
      setLoginForgotMessage(true);
      setTimeout(() => {
        setLoginForgotMessage(false);
        seDisabledButtont(false);
      }, 15000);
    } catch (err) {
      seDisabledButtont(false);
      if (err && err.response && err.response.data && err.response.data.message) {
        setErrorMsg(err.response.data.message);
      }
      setError(true);
      //console.log(err.data);
      setTimeout(() => {
        setError(false);
        setErrorMsg("");
      }, 15000);
    }
  };

  return (
    <div className="resetpassword">
      <div className="loginLeft"></div>
      <div className="loginWrapper">
        <div className="loginTitle">
          <span>{TITLE} </span>
          <span> </span>
          <span>{VERSION}</span>
        </div>
        <div className="loginLogo">
          <LockOutlinedIcon className="loginIcon" />
        </div>
        <h3 className="loginTitle">Reset password</h3>

        <form className="loginBox" onSubmit={handleResetPswd} autoComplete="off">
          <TextField
            label="Email"
            sx={{ mt: 2 }}
            error={err}
            required
            variant="outlined"
            type="email"
            margin="normal"
            onChange={(e) => {
              setEmail(e.target.value);
              setError(false);
            }}
          />

          {err && <span className="spanError">{errMsg ? errMsg : "Wrong Email"}</span>}
          <Button type="submit" variant="contained" disabled={disabledButton} sx={{ mt: 2 }}>
            RESET
          </Button>

          <div className="loginForgot">
            <Link to="/login" className="backToLogin-link" style={{ textDecoration: "none" }}>
              Back to login
            </Link>
          </div>

          {loginForgotMessage && (
            <span className="loginForgotMessage">Check your email (incoming or spam folder).</span>
          )}
          <span className="loginCopyright">Copyright © {year} Bamboo Strategic Media.</span>
        </form>
      </div>
    </div>
  );
}
