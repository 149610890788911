import { Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
  sx?: any;
  hide?: boolean;
}
export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, sx, hide } = props;

  return (
    <>
      {!hide && value === index && <Box sx={sx ? sx : { py: 2 }}>{children}</Box>}
      {hide && <Box sx={sx ? sx : { py: 2 }}>{children}</Box>}
    </>
  );
};

export default TabPanel;
