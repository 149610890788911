import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { IItemTab, IParamsForColums } from "../../models/list";

import { DeleteOutline } from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import TranslationlogPageContent from "./TranslationlogPageContent";
import InfoPopper from "../InfoPopper";

export const translationlogColumns = ({ handleActionItem }: IParamsForColums) => {
  const colums = [
    {
      field: "id",
      headerName: "№",
      width: 20,
    },
    {
      field: "name",
      headerName: "Layout ID / Name",
      width: 290,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              width: "100%",
              whiteSpace: "normal",
              display: "flex",
              alignItems: "flex-start",

              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                whiteSpace: "normal",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                //justifyContent: "space-between",
                color: "primary.main",
              }}
            >
              {params.row.layoutId && (
                <Box>
                  <Link to={`/translationlogs/${params.row?._id}?tab=0`} className="break-all">
                    {params.row.layoutId}{" "}
                  </Link>
                </Box>
              )}

              {params.row.name && (
                <Box>
                  <Link to={`/translationlogs/${params.row?._id}?tab=0`} className="break-all">
                    {params.row.name}
                  </Link>
                </Box>
              )}

              {/* <Link to={`/translationlogs/${params.row?._id}?tab=0`} className="break-all">
              {params.row?.name}
            </Link> */}
            </Box>
            {params.row.notes && <InfoPopper text={params.row.notes} />}
          </Box>
        );
      },
    },

    {
      field: "date",
      headerName: "File name / Date",
      width: 280,
      renderCell: (params: any) => {
        return (
          <>
            <Box
              sx={{
                whiteSpace: "normal",
              }}
            >
              <Box sx={{ color: "text.primary" }}>{params.row?.fileName}</Box>
              <Box sx={{ color: "text.secondary" }}>{params.row?.date}</Box>
            </Box>
          </>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              whiteSpace: "normal",
            }}
          >
            {!params?.row?.error ? (
              <Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    color: params?.row?.status === "success" ? "success.main" : "info.main",
                  }}
                >
                  {params?.row?.status}
                </Typography>
              </Box>
            ) : (
              <Box>
                {" "}
                <Typography
                  sx={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    color: "error.main",
                  }}
                >
                  Error
                </Typography>
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      field: "translateService",
      headerName: "Service",
      width: 120,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              whiteSpace: "normal",
            }}
          >
            <Box>
              <Typography variant="body2" sx={{}}>
                {params?.row?.translateService ? params?.row?.translateService : "googleTranslate"}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "action",
      width: 120,
      headerName: "Action",
      renderCell: (params: any) => {
        return (
          <div className="cellAction">
            <Link to={`/translationlogs/${params.row._id}?tab=0`}>
              <Tooltip title="Edit">
                <IconButton>
                  <CreateOutlinedIcon className="icon text-green-500" />
                </IconButton>
              </Tooltip>
            </Link>
            <Box>
              <Tooltip title="Copy Excel path">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/translations/${params.row?.fileName}`,
                    );
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <div
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                handleActionItem(params.row._id, "Delete");
              }}
            >
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteOutline className="deleteIcon" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];
  return colums;
};

export const initPaginateTranslationlog = {
  totalItems: 0,
  perPage: 25,
  totalPages: 0,
  currentPage: 1,
  search: "",
};

export const TranslationlogTabsData: IItemTab[] = [
  { label: "Content", component: [TranslationlogPageContent] },
];
export const translationlogTransformFunction = (d: any) => {
  const dd = new Date(d.createdAt);
  if (!d.layoutId && d.receivedData && d.receivedData.FieldTextData) {
    const keys = Object.keys(d.receivedData.FieldTextData);

    if (keys.length > 0) {
      d.layoutId = keys[0];
    }
  }
  if (!d.layoutId) {
    d.layoutId = "????";
  }

  return { ...d, date: dd.toLocaleString() };
};
