import React, { useContext, useEffect, useRef, useState } from "react";
import { TranslationlogClass } from "src/models/translationlog";
import { IPropsItemInList } from "src/models/list";
import { PrettyDeepJSON } from "../prettyJson/PrettyJson";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Collapse,
  TextField,
  Typography,
} from "@mui/material";
import { useAppSelector } from "src/hooks/hooks";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import CheckIcon from "@mui/icons-material/Check";
import LangsList from "../setting/LangsList";
import { AppGlobalContext } from "src/context/appGlobalContext";
let logSaveTimeOut: any;
//changedData = false;

const TranslationlogPageContent = ({
  onSubmit,
  initData,
}: IPropsItemInList<TranslationlogClass>) => {
  const [file, setFile] = useState<any>();
  const { authData } = useAppSelector((state) => state);
  const changedData = useRef(false);
  const headers = {
    "Contetnt-Type": "multipart/form-data",
    "x-access-token": "",
  };
  const headersBody = {
    "x-access-token": "",
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (authData.user) {
    headers["x-access-token"] = `Bearer ${authData.user.token}`;
    headersBody["x-access-token"] = `Bearer ${authData.user.token}`;
  }
  if (!initData.name) {
    initData.name = "";
  }
  const inputFileForUpload = useRef<HTMLInputElement>(null);
  const [item, setItem] = useState<any>(new TranslationlogClass(initData));
  const [loading, setLoading] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successSendTranslation, setSuccessSendTranslation] = useState(false);

  const { emitGlobalEvent } = useContext(AppGlobalContext);
  //console.log(initData);

  // const [collapse, setSollapse] = useReducer(
  //   (state: { [key: string]: boolean }, newState: { [key: string]: boolean }) => ({
  //     ...state,
  //     ...newState,
  //   }),
  //   {},
  // );

  const onSelectFileHandler = async (files: any) => {
    setFile(files[0]);
  };
  //console.log(file);
  const handleUplod = async () => {
    if (loading) {
      return;
    }
    if (inputFileForUpload.current) {
      inputFileForUpload.current.value = "";
    }

    setLoading(true);
    setSuccess(false);

    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await fetch(`/api/humusha/${initData._id}`, {
        method: "PUT",
        body: formData,
        headers,
      });
      if (res.status !== 200 && res.status !== 201) {
        throw await res.json();
      }
      const responseData = await res.json();
      //console.log(responseData);
      setItem((prev: any) => {
        return { ...prev, secondModifiedData: responseData };
      });
      setFile(undefined);
      setSuccess(true);
      onSubmit(null, { success: true });
    } catch (err: any) {
      console.log(err);
      onSubmit(null, { error: { message: err?.message || err[0] || "server error" } });
    }
    setLoading(false);
  };
  const handleSendTranslatedData = async () => {
    if (sendingData) {
      return;
    }

    setSuccessSendTranslation(false);

    setSendingData(true);
    try {
      const res = await fetch(`/api/humusha/${initData._id}`, {
        method: "GET",
        headers: headersBody,
      });
      if (res.status !== 200 && res.status !== 201) {
        throw await res.json();
      }
      const response = await res.json();
      console.log(response);

      setItem((prev: TranslationlogClass) => {
        return { ...prev, sendTranslatedData: transformResponse(response) };
      });

      onSubmit(null, { success: true });
      setSuccessSendTranslation(true);
    } catch (err: any) {
      console.log(err);
      onSubmit(null, { error: { message: err?.message || err[0] || "server error" } });
    }
    setSendingData(false);
    const transformResponse = (data: any[]) => {
      return data.map((d: any) => {
        const date = new Date(d.date);
        d.date = date.toLocaleString();
        return d;
      });
    };
  };

  const handleChangeInput = (data: { value: string; name: string }) => {
    const d = { [data.name]: data.value };
    setItem((prev: any) => {
      return { ...prev, ...d };
    });
    if (logSaveTimeOut) {
      clearTimeout(logSaveTimeOut);
    }
    logSaveTimeOut = setTimeout(() => saveLogDataInServer(d), 500);
  };
  const saveLogDataInServer = async (itemForUpdate: any) => {
    //console.log(itemForUpdate);
    try {
      const uri = `/api/translationlogs/${item._id}`;

      const res = await fetch(uri, {
        headers: headersBody,
        method: "PUT",
        body: JSON.stringify(itemForUpdate),
      });
      console.log(res);

      if (res.status !== 200 && res.status !== 201) {
        const error = await res.json();
        console.log(error);
        throw error;
      }
      const updatedItem = await res.json();
      onSubmit(null, { success: true });
      emitGlobalEvent({
        event: "itemUpdated",
        data: { item: updatedItem, type: "translationlogs" },
      });
    } catch (err: any) {
      onSubmit(null, { error: { message: err?.message || err[0] || "server error" } });
      console.log(err);
    }
  };
  const createFile = async () => {
    changedData.current = true;
    try {
      const res = await fetch(`/api/humusha/createfile/${item._id}`, {
        headers: {
          "x-access-token": headers["x-access-token"],
        },
        method: "GET",
      });
      if (res.status !== 200 && res.status !== 201) {
        throw await res.json();
      }
      //const d = await res.json();
      //console.log(res);
      onSubmit(null, { success: true });
    } catch (err: any) {
      onSubmit(null, { error: { message: err?.message || err[0] || "server error" } });
      console.log(err);
    }
  };

  useEffect(() => {
    setItem(new TranslationlogClass(initData));
  }, [initData]);

  return (
    <>
      {!initData._id ? (
        <Box>Loading...</Box>
      ) : (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            paddingLeft: "20px",
          }}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              flexWrap: { xs: "wrap", sm: "wrap", md: "wrap", lg: "wrap" },
              paddingBottom: "20px",
              paddingRight: "20px",
              gap: 1,
            })}
          >
            <Box sx={{ display: "flex", gap: 2, my: 1 }}>
              <TextField
                name="name"
                placeholder="Name"
                autoComplete="off"
                variant="standard"
                size="small"
                value={item.name}
                onChange={(e) => handleChangeInput(e.target)}
                sx={{ width: "30%x" }}
              />
              <TextField
                name="notes"
                placeholder="Notes"
                autoComplete="off"
                variant="standard"
                size="small"
                value={item.notes}
                onChange={(e) => handleChangeInput(e.target)}
                sx={{ width: "50%" }}
              />
            </Box>
            {initData.error && (
              <Box sx={{ display: "flex" }}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  <Box sx={{ mt: 1 }}>
                    <PrettyDeepJSON data={initData.error} copyToClipboard={true} />
                  </Box>
                </Alert>
              </Box>
            )}
            {initData.languages && initData.languages.length && (
              <LangsList code3={initData.languages} />
            )}

            <Box
              sx={{
                "& span": {
                  fontWeight: 600,
                },
              }}
            >
              <Typography variant="body2">
                <span>Service: </span> {initData.translateService}
              </Typography>
              <Typography variant="body2">
                <span>File: </span> {`${window.location.origin}/translations/${item.fileName}`}
              </Typography>
              <Typography variant="body2">
                <span>Layout ID: </span> {item.layoutId}
              </Typography>
            </Box>

            {initData.initRequest && <DataInLog title="Init Request" data={initData.initRequest} />}
            {initData.receivedData && (
              <DataInLog title="Received Data" data={initData.receivedData} />
            )}
            {initData.firstModifiedData && (
              <DataInLog title="FirstModified Data" data={initData.firstModifiedData} />
            )}
            {initData.slicedValues && (
              <DataInLog title="Sliced Values" data={initData.slicedValues} />
            )}
            {initData.serviceRequestData &&
              initData.serviceRequestData.length > 0 &&
              initData.serviceRequestData.map((item: any, i: number) => (
                <React.Fragment key={i}>
                  {item && <DataInLog title={`Service Request Data  ${i}`} data={item} />}
                </React.Fragment>
              ))}
            {initData.serviceAnswer && (
              <DataInLog title="Service Answer" data={initData.serviceAnswer} />
            )}
            {initData.combinedTranslatedData && (
              <DataInLog title="Combined Translated Data" data={initData.combinedTranslatedData} />
            )}
            {item.secondModifiedData && (
              <DataInLog
                open={success}
                title="Translated Result For the nex API"
                data={item.secondModifiedData}
              />
            )}
            {item.sendTranslatedData && (
              <DataInLog
                open={successSendTranslation}
                title="Results of sending translated data to third-party API"
                data={item.sendTranslatedData}
              />
            )}
          </Box>
          <Box
            sx={{
              borderLeft: "0.5px solid",
              borderLeftColor: "action.disabledBackground",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1,
                position: "sticky",
                top: "120px",
                paddingLeft: "20px",
              }}
            >
              {initData._id && !initData.error && (
                <>
                  {item.fileName && (
                    <Typography variant="body2" sx={{ color: "text.primary" }}>
                      {item.fileName}
                    </Typography>
                  )}
                  {item.date && <Typography variant="body2">{item.date}</Typography>}
                  {item.status === "success" && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",

                        height: "38px",
                        width: "150px",
                        border: "0.5px solid",
                        borderRadius: "3px",
                        borderColor: "action.disabled",
                        borderLeftWidth: "10px",
                        borderLeftColor: "success.main",
                        paddingLeft: "10px",
                        gap: "20px",
                      }}
                    >
                      <CheckIcon color="success" />
                      <Typography variant="body2" sx={{ textTransform: "uppercase" }}>
                        {item.status}
                      </Typography>
                    </Box>
                  )}
                  {item.status === "pending" && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",

                        height: "38px",
                        width: "150px",
                        border: "0.5px solid",
                        borderRadius: "3px",
                        borderColor: "info.main",
                        borderLeftWidth: "10px",
                        borderLeftColor: "info.main",
                        paddingLeft: "10px",
                        gap: "20px",
                      }}
                    >
                      <CheckIcon color="info" />
                      <Typography variant="body2" sx={{ textTransform: "uppercase" }}>
                        {item.status}
                      </Typography>
                    </Box>
                  )}
                  {item.status === "error" && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          height: "38px",
                          width: "150px",
                          border: "0.5px solid",
                          borderRadius: "3px",
                          borderColor: "error.main",
                          borderLeftWidth: "10px",
                          borderLeftColor: "error.main",
                          paddingLeft: "10px",
                          gap: "20px",
                        }}
                      >
                        <CheckIcon color="error" />
                        <Typography variant="body2" sx={{}}>
                          {item.status}
                        </Typography>
                      </Box>
                      <Typography variant="body2" color="error" sx={{}}>
                        {item.statusInfo}
                      </Typography>
                    </>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      component="label"
                      color="success"
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        createFile();
                      }}
                    >
                      Renew XLSX
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a href={`/translations/${initData.fileName}`} download>
                      <Button variant="outlined" component="label" color="success">
                        Download XLSX
                      </Button>
                    </a>
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                    <Box>
                      <Button variant="outlined" component="label">
                        Select file
                        <input
                          ref={inputFileForUpload}
                          hidden
                          type="file"
                          onChange={(e) => onSelectFileHandler(e.target.files)}
                        />
                      </Button>
                    </Box>

                    {file && <Typography variant="body2">{file.name}</Typography>}
                    <Box>
                      {file && (
                        <Button
                          variant="contained"
                          disabled={loading}
                          onClick={() => {
                            handleUplod();
                          }}
                        >
                          Upload
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", my: 1, position: "relative" }}>
                    <Button
                      variant="contained"
                      disabled={sendingData}
                      onClick={() => {
                        handleSendTranslatedData();
                      }}
                    >
                      Send Translated Data
                    </Button>
                    {sendingData && (
                      <CircularProgress
                        size={24}
                        color="primary"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
          {/* {error && (
            <Box sx={{ display: "flex" }}>
              <Alert severity="error" variant={darkMode === "dark" ? "filled" : undefined}>
                <AlertTitle>Error</AlertTitle>
                <Box sx={{ mt: 1 }}>
                  <PrettyDeepJSON data={error} copyToClipboard={true} />
                </Box>
              </Alert>
            </Box>
          )}{" "}
          {success && (
            <Box sx={{ display: "flex" }}>
              <Alert severity="success" variant={darkMode === "dark" ? "filled" : undefined}>
                <AlertTitle>Success</AlertTitle>
              </Alert>
            </Box>
          )} */}
        </Box>
      )}
    </>
  );
};

export default TranslationlogPageContent;

const DataInLog = ({ title, data, open }: { title: string; data: any; open?: boolean }) => {
  const [collapse, setCollapse] = useState(false);
  useEffect(() => {
    if (open) {
      setCollapse(true);
    }
  }, [open]);
  return (
    <Box>
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        {collapse && <KeyboardArrowDownOutlinedIcon sx={{ color: "primary.main" }} />}
        {!collapse && <KeyboardArrowRightOutlinedIcon sx={{ color: "primary.main" }} />}
        <Typography variant="body2" color="primary" sx={{}}>
          {title}
        </Typography>
      </Box>

      <Collapse in={collapse}>
        <Box sx={{ mt: 1, maxHeight: "300px", overflow: "auto", maxWidth: "700px" }}>
          <PrettyDeepJSON data={data} copyToClipboard={true} />
        </Box>
      </Collapse>
    </Box>
  );
};
