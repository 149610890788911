import { createSlice } from "@reduxjs/toolkit";
import appData from "../../appdata.json";
const LSPath = (appData.STORAGE || "ls") + "-SIDE_BAR";
const INIT_STATE: boolean = JSON.parse(localStorage.getItem(LSPath) ?? "false");

export const sideBarSlice = createSlice({
  name: "sideBar",
  initialState: INIT_STATE,
  reducers: {
    closeSideBar: (state) => {
      state = true;
      localStorage.setItem(LSPath, JSON.stringify(state));
      return state;
    },
    openSideBar: (state) => {
      state = false;
      localStorage.setItem(LSPath, JSON.stringify(state));
      return state;
    },
    toggleSideBar: (state) => {
      state = !state;
      localStorage.setItem(LSPath, JSON.stringify(state));
      return state;
    },
  },
});

export const sideBarActions = sideBarSlice.actions;
export const { closeSideBar, openSideBar, toggleSideBar } = sideBarSlice.actions;
export default sideBarSlice.reducer;
