import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
//import { useReactState } from "src/hooks/useReactState";
import { useAppSelector } from "../hooks/hooks";
import AdditionalConfirmComponent from "./AdditionalConfirmComponent";

interface IProps {
  openModal: boolean;
  handleCloseModal: () => void;
  modalTitle: string;
  setOpenModal: (val: boolean) => void;
  handleAction: () => void;

  additionalComfirm?: boolean;
  additionalTitle?: string;
  additionalPlaceholder?: string;
  adiitionalCheckValue?: string;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 410,
  bgcolor: "background.default",
  border: "0.5px solid #9b9b9b",
  boxShadow: 24,
  p: 2,
};
const Appmodal = ({
  openModal,
  handleCloseModal,
  modalTitle,
  setOpenModal,
  handleAction,
  additionalComfirm,
  additionalTitle,
  additionalPlaceholder,
  adiitionalCheckValue,
}: IProps) => {
  const { darkMode } = useAppSelector((state) => state);
  const [checkData, setCheckData] = useState(false);
  const handleConfirm = () => {
    handleAction();
  };
  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        BackdropProps={
          darkMode === "dark" ? { style: { backgroundColor: "rgb(255, 255, 255,0.15)" } } : {}
        }
      >
        <Box sx={style}>
          <Typography
            sx={{ textAlign: "center" }}
            variant="h6"
            component="div"
            color="text.secondary"
            className="whitespace-normal"
          >
            <span className="break-words">{modalTitle}</span>
          </Typography>

          {additionalComfirm && (
            <AdditionalConfirmComponent
              additionalTitle={additionalTitle}
              additionalPlaceholder={additionalPlaceholder}
              adiitionalCheckValue={adiitionalCheckValue}
              setCheckData={setCheckData}
            />
          )}

          <Box sx={{ px: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-around", padding: "20px" }}>
              <Button
                size="small"
                variant="contained"
                className="deleteYes"
                disabled={additionalComfirm && !checkData}
                onClick={(e) => {
                  setOpenModal(false);
                  handleConfirm();
                }}
              >
                YES
              </Button>
              <Button
                size="small"
                variant="contained"
                color="error"
                className="deleteNo"
                onClick={(e) => setOpenModal(false)}
              >
                NO
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Appmodal;
