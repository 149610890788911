import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import React, { useEffect, useState, useReducer } from "react";
import { useActions } from "src/hooks/actions";
import { useFetch } from "src/hooks/fetchData";
import { useAppSelector } from "src/hooks/hooks";
import { UserClass } from "src/models/user";
import Appmodal from "../Appmodal";

type TranslateService = "chatGPT" | "googleTranslate" | "deepL";
interface ISetting {
  emailNotification?: boolean;
  languages?: ILang[];
  autoSendTranslatedData?: boolean;
  translateService: TranslateService;
  apiKey: string;
}
export interface ILang {
  code2: string;
  code3: string;
  full: string;
  checked?: boolean;
}

const Settings = () => {
  const [langsArraFromServer, setLangsArrayFromServer] = useState<ILang[]>([]);
  const [langsArray, setLangsArray] = useState<ILang[]>([]);
  const { darkMode, authData } = useAppSelector((state) => state);
  const [openModal, setOpenModal] = useState(false);
  const headers = {
    "x-access-token": "",
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (authData.user) {
    headers["x-access-token"] = `Bearer ${authData.user.token}`;
  }
  const _id = authData.user?._id || "";

  const { updateAuth } = useActions();

  const INIT_SETTING: ISetting = {
    emailNotification: false,
    languages: [],
    autoSendTranslatedData: false,
    translateService: "googleTranslate",
    apiKey: "",
  };
  const [settings, setSettings] = useReducer(
    (state: ISetting, newState: Partial<ISetting>) => ({
      ...state,
      ...newState,
    }),
    INIT_SETTING,
  );

  const {
    error,
    success,
    updateItem,
    fetchItem,
    setError,
    setSuccess,
    item,

    loading,
  } = useFetch<UserClass>(`/api/users`, { _id: _id });

  const getLangData = async () => {
    try {
      const res = await fetch(`/assets/lang.json`, {
        method: "GET",
        headers,
      });
      if (res.status !== 200 && res.status !== 201) {
        throw await res.json();
      }
      const responseData = await res.json();

      setLangsArrayFromServer(
        responseData.map((l: ILang) => {
          if (
            authData.user?.settings?.languages &&
            authData.user?.settings?.languages.some((ll: ILang) => ll.code2 === l.code2)
          ) {
            l.checked = true;
          }

          return { ...l };
        }),
      );
    } catch (err: any) {
      setError(err?.message || err[0] || "server error");
    }
  };

  useEffect(() => {
    if (item && item._id) {
      if (item.settings) {
        //console.log(item.settings);
        setSettings(item.settings);
      }
    }
    if (item && item._id && langsArraFromServer.length > 0) {
      setLangsArray(
        langsArraFromServer.map((lang) => {
          if (
            item.settings &&
            item.settings.languages &&
            item.settings.languages.length &&
            item.settings.languages.some((l) => l.code2 === lang.code2)
          ) {
            lang.checked = true;
          } else {
            lang.checked = false;
          }
          return lang;
        }),
      );
    }
  }, [item, langsArraFromServer]);

  const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target);
    const value = e.target.name === "autoSendTranslatedData" ? e.target.checked : e.target.value;
    const objForUpdate = { [e.target.name]: value };
    setSettings(objForUpdate);
    setTimeout(() => {
      updateItem({ _id: _id, settings: objForUpdate });
    }, 200);
  };

  const upd = async (data: ILang[]) => {
    await updateItem({ _id: _id, settings: { languages: data } });
    const updatedUser = {
      ...authData.user,
      settings: {
        languages: data,
        emailNotification: settings.emailNotification,
        autoSendTranslatedData: settings.autoSendTranslatedData,
      },
    };
    if (updatedUser) {
      updateAuth(updatedUser);
    }
    setSettings({ languages: data });
  };

  const handleCloseModal = () => setOpenModal(false);

  const generateNewAPIKey = async () => {
    try {
      const res = await fetch(`/api/humusha/generateNewAPIKey`, {
        method: "GET",
        headers,
      });
      if (res.status !== 200 && res.status !== 201) {
        throw await res.json();
      }
      const responseData = await res.json();
      await updateItem({ _id: _id, settings: responseData });
      setSettings(responseData);
      console.log(responseData);
    } catch (err: any) {
      setError(err?.message || err[0] || "server error");
    }
  };

  useEffect(() => {
    fetchItem();
    getLangData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const data = langsArray.reduce((arrL: ILang[], lang: ILang) => {
      if (lang.checked) {
        const d = { ...lang };
        delete d.checked;
        arrL.push(d);
      }
      return arrL;
    }, []);
    //updateItem({ _id: _id, settings: { languages: data } });
    console.log(item._id && settings?.languages?.length !== data.length);
    if (item._id && settings?.languages?.length !== data.length) {
      upd(data);
    }
    // eslint-disable-next-line
  }, [langsArray]);

  return (
    <div>
      <Appmodal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        setOpenModal={setOpenModal}
        modalTitle={`Generate a new API key?`}
        handleAction={generateNewAPIKey}
      />
      <Stack
        sx={{
          width: { xs: "93%", sm: "50%", md: "40%", lg: "30%" },
          right: 17,
          top: { xs: "59px", sm: "59px", md: "59px", lg: "59px" },
          position: "fixed",
          zIndex: 1000,
        }}
      >
        <Collapse
          in={!!(error && error !== "")}
          onClick={() => {
            setError("");
          }}
        >
          <Alert severity="error" variant={darkMode === "dark" ? "filled" : undefined}>
            <AlertTitle>Error</AlertTitle>
            <strong>{error}</strong>
          </Alert>
        </Collapse>
        <Collapse
          in={!!(success && success !== "")}
          onClick={() => {
            setSuccess("");
          }}
        >
          <Alert severity="success" variant={darkMode === "dark" ? "filled" : undefined}>
            <AlertTitle>Success</AlertTitle>
            <strong>{success}</strong>
          </Alert>
        </Collapse>
      </Stack>
      {loading && <p className="text-center">Loading...</p>}

      {!loading && (
        <Grid container spacing={3}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="relative !pt-0"
            sx={{ mt: 3 }}
            item
            style={{ paddingTop: "0px !important" }}
          >
            <Typography sx={{ mb: 1, color: "text.secondary" }}>Settings</Typography>
            <Card>
              <CardContent>
                {authData.user && authData.user.roles.some((r: any) => r.role === "SuperAdmin") && (
                  <>
                    <Box>
                      <Typography>API key</Typography>
                    </Box>
                    <Box sx={{ mt: 1, display: "flex", alignItems: "center", gap: "2px" }}>
                      <Box
                        sx={{ border: "0.5px solid", borderRadius: "5px", py: "5px", px: "10px" }}
                      >
                        {settings.apiKey}
                      </Box>
                      <Box>
                        <Tooltip title="Copy api key">
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(`${settings.apiKey}`);
                            }}
                          >
                            <ContentCopyOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      <Button
                        variant="outlined"
                        onClick={() => setOpenModal(true)}
                        sx={{ ml: "15px" }}
                      >
                        Generate new API key
                      </Button>
                    </Box>

                    <Box sx={{ my: 2 }}>
                      <FormControl>
                        <FormLabel>Service</FormLabel>
                        <RadioGroup
                          row
                          name="translateService"
                          value={settings.translateService}
                          onChange={handleChangeData}
                        >
                          <FormControlLabel
                            value="googleTranslate"
                            control={<Radio />}
                            label="googleTranslate"
                          />
                          <FormControlLabel value="chatGPT" control={<Radio />} label="chatGPT" />
                          <FormControlLabel value="deepL" control={<Radio />} label="deepL" />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                    <Box sx={{ my: 2 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="autoSendTranslatedData"
                            checked={settings.autoSendTranslatedData}
                            onChange={handleChangeData}
                          />
                        }
                        label={
                          <Box
                            component="div"
                            style={{
                              whiteSpace: "normal",
                            }}
                          >
                            <span className="break-all">Enable auto send translation</span>
                          </Box>
                        }
                      />
                    </Box>

                    <Box sx={{ my: 2 }}>
                      <Typography sx={{ mb: 1 }} variant="body2">
                        Languages
                      </Typography>

                      <Box sx={{ my: 2, display: "flex", flexWrap: "wrap" }}>
                        {langsArray.map((lang: any, i: number) => (
                          <LangItem lang={lang} setLangsArray={setLangsArray} key={i} />
                        ))}
                      </Box>
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Settings;

const LangItem = ({ lang, setLangsArray }: { lang: any; setLangsArray: (d: any) => void }) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLangsArray((prev: ILang[]) => [
      ...prev.map((l) => {
        if (l.code2 === lang.code2) {
          l.checked = event.target.checked;
        }
        return l;
      }),
    ]);
  };
  return (
    <Box sx={{ width: "200px" }}>
      <FormGroup>
        <FormControlLabel
          checked={lang.checked}
          control={<Checkbox value={lang.checked} onChange={onChange} />}
          label={lang.full}
        />
      </FormGroup>
    </Box>
  );
};
