import { useAppSelector } from "../hooks/hooks";
import Login from "../pages/login/Login";
export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const { authData } = useAppSelector((state) => state);
  return (
    <>
      {authData.user && children} {!authData.user && <Login />}
    </>
  );
};
