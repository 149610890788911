import { configureStore } from "@reduxjs/toolkit";
import { userApi } from "./users/users.api";
import { userkeysApi } from "./users/userkeys.api";

import { authenticationReducer } from "./auth/auth.slice";
import darkModeReducer from "./darkMode/darkModeSlice";
import sideBarReducer from "./sideBar/sideBarSlice";
import actionResultReducer from "./actionResults/actionResultSlice";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

export const store = configureStore({
  reducer: {
    darkMode: darkModeReducer,
    sideBarClose: sideBarReducer,
    actionResult: actionResultReducer,

    [userApi.reducerPath]: userApi.reducer,
    [userkeysApi.reducerPath]: userkeysApi.reducer,

    authData: authenticationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware, userkeysApi.middleware),
});
// for refetchOnFocus: true
setupListeners(store.dispatch);

// custom type for store - use it in hooks
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
