import "./home.scss";
import Navbar from "../components/navbar/Navbar";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sideBar/Sidebar";
import useWindowDimensions from "../hooks/windowDimensions";
import { useContext, useEffect, useRef, useState } from "react";

import { AppGlobalContext } from "src/context/appGlobalContext";
import { useAppSelector } from "src/hooks/hooks";

import { Box, CircularProgress } from "@mui/material";
import { AppTestpreviewContext } from "src/context/appOpenTestpreviewContext";

const Home = () => {
  const { windowWidth, windowHeight } = useWindowDimensions();
  const { sideBarClose } = useAppSelector((state) => state);

  const [loaded, setLoaded] = useState(false);
  const [loadedClass, setLoadedClass] = useState(false);
  const {
    openTestpreviewData,
    openTestpreviewTop,
    setOpenTestpreviewHeight,
    isResizing,
    setIsResizing,
  } = useContext(AppTestpreviewContext);

  const { appForRightSideBar, setWidthForRightSideBar, parangaForViewport } =
    useContext(AppGlobalContext);

  const mainContainer = useRef<HTMLDivElement>(null);
  const listContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 400);
    setLoadedClass(true);
    setTimeout(() => {
      setLoaded(true);
    }, 1000);

    document.onmouseup = function (e) {
      setIsResizing(false);
      //console.log("22console.log(isResizing)ssssssssssss");
      if (isResizing) {
        setIsResizing(false);
        //console.log("console.log(isResizing)ssssssssssss");
      }
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isResizing) {
      document.onmousemove = function (e: MouseEvent) {
        const newValue = e.clientY;
        if (newValue < 150) {
          return;
        }
        if (newValue > windowHeight - 150) {
          return;
        }
        if (mainContainer && mainContainer.current) {
          const element = mainContainer?.current as HTMLElement;

          element.style.bottom = `${windowHeight - e.clientY - 3}px`;
        }
        const testpreview = document.getElementById("testpreview");
        if (testpreview) {
          testpreview.style.top = Number(e.clientY + 3) + "px";
        }
        if (window.getSelection) {
          window.getSelection()?.removeAllRanges();
        } else if (document.getSelection) {
          document.getSelection()?.empty();
        }
        setOpenTestpreviewHeight(e.clientY);
      };
    } else {
      document.onmousemove = () => {};
      if (openTestpreviewData && mainContainer && mainContainer.current) {
        const element = mainContainer?.current as HTMLElement;
        element.style.bottom = `${windowHeight - openTestpreviewTop - 1}px`;
      } else if (mainContainer && mainContainer.current) {
        const element = mainContainer?.current as HTMLElement;
        element.style.bottom = `0px`;
      }
    }
    //eslint-disable-next-line
  }, [isResizing]);

  useEffect(() => {
    if (openTestpreviewData && mainContainer && mainContainer.current) {
      const element = mainContainer?.current as HTMLElement;
      element.style.bottom = `${windowHeight - openTestpreviewTop + 3}px`;
      const testpreview = document.getElementById("testpreview");

      if (testpreview) {
        testpreview.style.top = Number(openTestpreviewTop - 3) + "px";
      }
    } else if (!openTestpreviewData && mainContainer && mainContainer.current) {
      const element = mainContainer?.current as HTMLElement;
      element.style.bottom = "0px";
    }
    //eslint-disable-next-line
  }, [openTestpreviewData]);

  useEffect(() => {
    const widthForRightSideBar = windowWidth > 800 ? 380 : windowWidth;
    setWidthForRightSideBar(widthForRightSideBar);
    // set width and left

    if (openTestpreviewData && mainContainer && mainContainer.current) {
      const element = mainContainer?.current as HTMLElement;
      element.style.bottom = `${windowHeight - openTestpreviewTop - 1}px`;
      const testpreview = document.getElementById("testpreview");
      if (testpreview) {
        testpreview.style.top = openTestpreviewTop + "px";
      }
    }
    //eslint-disable-next-line
  }, [windowHeight, windowWidth, sideBarClose, appForRightSideBar]);

  return (
    <>
      {parangaForViewport && (
        <Box
          sx={{
            position: "fixed",
            display: "flex",
            width: "100wh",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
            inset: "0px",
            zIndex: 1111,
            top: 0,
            left: 0,
            opacity: 0.8,
            transition: "all 0.5s ease-in-out",
            backgroundColor: "action.disabled",
          }}
        >
          <CircularProgress disableShrink sx={{}} />
        </Box>
      )}
      {!loaded && (
        <div
          className={!loadedClass ? "parangaForSedeBarStart" : "disappear parangaForSedeBarStart"}
        >
          <CircularProgress disableShrink />
        </div>
      )}

      <Sidebar />
      <Navbar />
      <Box
        ref={mainContainer}
        sx={{
          position: "fixed",
          top: 50,
          bottom: 0,
          right: 0,

          left: windowWidth < 800 ? "0px" : !sideBarClose ? "140px" : "50px",
          scrollbarWidth: "thin",
          paddingLeft: "20px",
          overflowY: "auto",
          backgroundColor: "background.default",
          transition: "right .25s ease, left .15s ease",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              flexGrow: 1,
              paddingTop: "10px",

              paddingRight: "20px",

              paddingBottom: 3,
            }}
          >
            <Box ref={listContainer}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
