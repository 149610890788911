export class TranslationlogClass {
  _id?: string;
  id?: string;
  date: string;
  name: string;
  notes: string;
  initRequest: any;
  receivedData: any;
  firstModifiedData: any;
  slicedValues: any;
  serviceRequestData: any;
  serviceAnswer: any;
  combinedTranslatedData: any;
  secondModifiedData: any;
  createdAt: string;
  error?: any;
  fileName?: string;
  languages?: [];
  sendTranslatedData?: any[];
  layoutId: string;
  status: string;
  statusInfo: string;
  translateService: string;

  constructor(initData: Partial<TranslationlogClass> = {}) {
    if (typeof initData._id !== "undefined") {
      this._id = initData._id;
    }

    if (typeof initData.date === "undefined") {
      this.date = "";
    } else {
      this.date = initData.date;
    }
    if (typeof initData.createdAt === "undefined") {
      this.createdAt = "";
    } else {
      this.createdAt = initData.createdAt;
    }
    if (typeof initData.name === "undefined") {
      this.name = "";
    } else {
      this.name = initData.name;
    }
    if (typeof initData.notes === "undefined") {
      this.notes = "";
    } else {
      this.notes = initData.notes;
    }
    if (typeof initData.status === "undefined") {
      this.status = "";
    } else {
      this.status = initData.status;
    }
    if (typeof initData.statusInfo === "undefined") {
      this.statusInfo = "";
    } else {
      this.statusInfo = initData.statusInfo;
    }
    if (typeof initData.translateService === "undefined") {
      this.translateService = "googleTranslate";
    } else {
      this.translateService = initData.translateService;
    }
    if (typeof initData.initRequest === "undefined") {
      this.initRequest = {};
    } else {
      this.initRequest = initData.initRequest;
    }
    if (typeof initData.receivedData === "undefined") {
      this.receivedData = {};
    } else {
      this.receivedData = initData.receivedData;
    }
    if (typeof initData.firstModifiedData === "undefined") {
      this.firstModifiedData = {};
    } else {
      this.firstModifiedData = initData.firstModifiedData;
    }
    if (typeof initData.slicedValues === "undefined") {
      this.slicedValues = {};
    } else {
      this.slicedValues = initData.slicedValues;
    }
    if (typeof initData.serviceRequestData === "undefined") {
      this.serviceRequestData = [];
    } else {
      this.serviceRequestData = initData.serviceRequestData;
    }
    if (typeof initData.serviceAnswer === "undefined") {
      this.serviceAnswer = [];
    } else {
      this.serviceAnswer = initData.serviceAnswer;
    }
    if (typeof initData.combinedTranslatedData === "undefined") {
      this.combinedTranslatedData = [];
    } else {
      this.combinedTranslatedData = initData.combinedTranslatedData;
    }
    if (typeof initData.secondModifiedData === "undefined") {
      this.secondModifiedData = {};
    } else {
      this.secondModifiedData = initData.secondModifiedData;
    }
    if (typeof initData.fileName === "undefined") {
      this.fileName = ">>>?????";
    } else {
      this.fileName = initData.fileName;
    }
    if (typeof initData.sendTranslatedData === "undefined") {
      this.sendTranslatedData = [];
    } else {
      this.sendTranslatedData = initData.sendTranslatedData.map((d: any) => {
        const date = new Date(d.date);
        d.date = date.toLocaleString();
        return d;
      });
    }
    if (typeof initData.error !== "undefined") {
      this.error = initData.error;
    }
    if (typeof initData.layoutId === "undefined") {
      this.layoutId = "???????";
      if (this.receivedData && this.receivedData.FieldTextData) {
        const keys = Object.keys(this.receivedData.FieldTextData);
        if (keys.length > 0) {
          this.layoutId = keys[0];
        }
      }
    } else {
      this.layoutId = initData.layoutId;
    }
  }
}
