import MenuIcon from "@mui/icons-material/Menu";

import { useActions } from "../../hooks/actions";
import { useAppSelector } from "../../hooks/hooks";
import { useContext } from "react";
import useWindowDimensions from "../../hooks/windowDimensions";
import appData from "../../appdata.json";

import { AppGlobalContext } from "src/context/appGlobalContext";
import { Box, IconButton } from "@mui/material";

const Navbar = () => {
  const { appForRightSideBar } = useContext(AppGlobalContext);
  const { toggleSideBar } = useActions();
  const { sideBarClose, authData } = useAppSelector((state) => state);
  const { windowWidth } = useWindowDimensions();

  const { TITLE, VERSION } = appData as any;

  const handleToggleSideBar = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    toggleSideBar();
  };

  return (
    <Box
      sx={{
        position: "sticky",
        zIndex: 10,
        top: 0,
        height: "50px",
        borderBottom: "0.5px solid",
        borderBottomColor: "action.disabledBackground",
        px: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "primary.light",
        color: "background.default",
        gap: "10px",
        borderRight: "0.5px solid",
        borderRightColor: "action.disabledBackground",
      }}
    >
      <IconButton onClick={handleToggleSideBar}>
        <MenuIcon
          sx={{
            color: "background.default",
            transition: "transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            transform: sideBarClose ? "rotate(180deg)" : "",
          }}
        />
      </IconButton>

      {windowWidth > 800 && (
        <Box sx={{ fontSize: "14px", fontFamily: "inherit" }}>
          {TITLE} {VERSION}
        </Box>
      )}

      <Box
        sx={{
          minWidth: "0px",
          flexShrink: 3,
          display: "flex",
          justifyContent: "end",
          ml: "auto",
          gap: 1,
        }}
      >
        {(windowWidth > 870 || (!Boolean(appForRightSideBar) && windowWidth > 800)) && (
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: "14px",
              fontFamily: "inherit",
              paddingRight: "18px",
            }}
          >
            {authData.user?.email}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Navbar;
