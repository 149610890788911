import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  setUser,
  getUser,
  setAdminUser,
  setSuperAdminUser,
  getAdminUser,
  getSuperAdminUser,
  setAppFilterGroupToStorage,
} from "../../services/localStorage";
import { RootState } from "../../store";
import { AuthActionsList, IAuthState, IUser } from "../../models/user";
import axios from "axios";

const INIT_USER: IUser | null = getUser();
//console.log(INIT_USER);
const INITIAL_STATE: IAuthState = {
  user: INIT_USER,
  isFetching: false,
  error: false,
  force: false,
};

export const authenticationSlice = createSlice({
  name: "authData",
  initialState: INITIAL_STATE,
  reducers: {
    [AuthActionsList.LoginStart]: (state) => {
      //console.log(state);
      return {
        user: null,
        isFetching: true,
        error: false,
        force: false,
      };
    },
    [AuthActionsList.LoginSuccess]: (state, action: PayloadAction<IUser>) => {
      return {
        user: action.payload,
        isFetching: false,
        error: false,
        force: false,
      };
    },
    [AuthActionsList.Update]: (state, action: PayloadAction<any>) => {
      setUser(action.payload);
      //console.log(action.payload);
      return {
        user: action.payload,
        isFetching: false,
        error: false,
        force: false,
      };
    },
    [AuthActionsList.LoginFailure]: (state, action: PayloadAction<any>) => {
      setUser(null);
      setAdminUser(null);
      return {
        user: null,
        isFetching: false,
        error: true,
        force: false,
      };
    },
    [AuthActionsList.Loguot]: (state) => {
      setUser(null);
      setAdminUser(null);
      return {
        user: null,
        isFetching: false,
        error: false,
        force: false,
      };
    },
  },
});
export const authenticateUser = (userData: any) => async (dispatch: any, getState: any) => {
  try {
    //console.log("authenticateUser", startAuth);
    dispatch(startAuth());
    const res = await axios.post("/api/auth/signin", userData);
    const authData = res?.data || null;
    setUser(authData);
    dispatch(successAuth(authData));
    //history.push("/v1");
  } catch (err) {
    dispatch(errorAuth(err));
  }
};
export const forceLogin = (_id: string) => async (dispatch: any, getState: any) => {
  try {
    const currentUser = getUser();
    //console.log(currentUser);
    const role =
      currentUser.roles[0] && currentUser.roles[0].role === "SuperAdmin" ? "SuperAdmin" : "Admin";

    const token = currentUser.token;

    setAppFilterGroupToStorage("");

    const res = await axios.get(`/api/auth/forcelogin/${_id}`, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `Bearer ${token}`,
      },
    });
    const authData = res?.data || null;

    if (authData) {
      if (role === "SuperAdmin") {
        authData.fromSuperAdmin = true;
        setSuperAdminUser(currentUser);
      } else {
        authData.fromAdmin = true;
        setAdminUser(currentUser);
      }

      //dispatch(startAuth());

      setUser(authData);
      //dispatch(successAuth(authData));
    }

    setTimeout(() => {
      window.location.reload();
    }, 300);

    //history.push("/v1");
  } catch (err) {
    console.log(err);
    // const user = JSON.parse(getAdminUser());
    // dispatch(successAuth(user));
    // setAdminUser(null);
  }
};
export const forceLoginAdmin = (_id: string) => async (dispatch: any, getState: any) => {
  try {
    const currentUser = getUser();

    //console.log(currentUser);
    const role =
      currentUser.roles[0] && currentUser.roles[0].role === "SuperAdmin" ? "SuperAdmin" : "Admin";

    const token = currentUser.token;

    setAppFilterGroupToStorage("");

    //setAdminUser(getUser());

    const res = await axios.get(`/api/auth/forceloginadmin/${_id}`, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": `Bearer ${token}`,
      },
    });
    const authData = res?.data || null;
    //authData.fromAdmin = true;

    if (authData) {
      if (role === "SuperAdmin") {
        authData.fromSuperAdmin = true;
        setSuperAdminUser(currentUser);
      } else {
        authData.fromAdmin = true;
        setAdminUser(currentUser);
      }

      console.log(authData);
      //dispatch(startAuth());
      setUser(authData);
      //dispatch(successAuth(authData));
    }

    setTimeout(() => {
      window.location.reload();
    }, 300);

    //history.push("/v1");
  } catch (err) {
    console.log(err);
    // const user = JSON.parse(getAdminUser());
    // dispatch(successAuth(user));
    // setAdminUser(null);
  }
};

export const backToAdmin = () => async (dispatch: any) => {
  try {
    const currentUser = getUser();
    if (currentUser.fromAdmin) {
      const authData = getAdminUser();
      setUser(authData);
      dispatch(successAuth(authData));
      setAdminUser(null);
    } else if (currentUser.fromSuperAdmin) {
      const authData = getSuperAdminUser();
      setUser(authData);
      dispatch(successAuth(authData));
      setSuperAdminUser(null);
    }
  } catch (err) {
    console.log(err);
    setAdminUser(null);
  }
};

//export const authenticationActions = authenticationSlice.actions;
export const {
  [AuthActionsList.LoginStart]: startAuth,
  [AuthActionsList.LoginSuccess]: successAuth,
  [AuthActionsList.LoginFailure]: errorAuth,
  [AuthActionsList.Loguot]: loguotAuth,
  [AuthActionsList.Update]: updateAuth,
} = authenticationSlice.actions;

export const selectAuthentication = (state: RootState) => state.authData;
export const authenticationReducer = authenticationSlice.reducer;
