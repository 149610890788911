import { useEffect, useState } from "react";
import "./resetpasswordform.css";
import "./resetpasswordform.scss";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
//import validator from "validator";
import { useAppSelector } from "../../hooks/hooks";
import appData from "../../appdata.json";

import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { TextField } from "formik-mui";
const dataSchema = yup.object({
  password: yup
    .string()
    .required("Required")
    .min(12, "Password must be 12 characters long.")
    .max(50, "Must be 50 characters or less.")
    .matches(/[0-9]/, "Password must contain at least one number.")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/[^\w]/, "Password must contain at least one symbol."),
  confirmPassword: yup
    .string()
    .required("Required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref("password")], "Passwords do not match."),
    }),
});
export default function Resetpasswordform() {
  const { TITLE, VERSION } = appData;
  const today = new Date();
  const year = today.getFullYear();

  // eslint-disable-next-line
  const [data, setData] = useState({ password: "" });

  let { key } = useParams();
  //console.log("key", key);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [keyValid, setKeyValid] = useState(false);
  const [err, setError] = useState("");
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const { authData } = useAppSelector((state) => state);

  //console.log(user);

  // const validate = (value) => {
  //   if (
  //     validator.isStrongPassword(value, {
  //       minLength: 12,
  //       minLowercase: 1,
  //       minUppercase: 1,
  //       minNumbers: 1,
  //       minSymbols: 1,
  //     })
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const handleSubmit = async (dataForUpdate) => {
    const { password } = dataForUpdate;

    try {
      await axios.post("/api/auth/change-password/" + key, {
        password: password,
      });
      //console.log(res);

      setSuccess(true);
    } catch (err) {
      setKeyValid(false);
      if (err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError("Wrong key!");
      }
      console.log(err);
    }
  };

  useEffect(() => {
    const sendKey = async () => {
      try {
        await axios.get("/api/auth/check-access/" + key);
        //console.log(res);
        setKeyValid(true);
      } catch (err) {
        console.log(err);
        if (err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError("Wrong key!");
        }
      }
    };
    sendKey();
  }, [key]);

  useEffect(() => {
    setErrorMessage("");
  }, [password]);

  useEffect(() => {
    if (authData.user) {
      navigate("/");
    }
  }, [authData.user, navigate]);

  return (
    <div className="resetpasswordform">
      <div className="loginLeft"></div>
      <div className="loginWrapper">
        <div className="loginTitle">
          <span>{TITLE} </span>
          <span> </span>
          <span>{VERSION}</span>
        </div>
        <div className="loginLogo">
          <LockOutlinedIcon className="loginIcon" />
        </div>
        <h3 className="loginTitle">New password</h3>
        {err && (
          <>
            <span className="spanError">{err}</span>

            <div className="loginForgot">
              <Link to="/login" className="backToLogin-link" style={{ textDecoration: "none" }}>
                Back to login
              </Link>
            </div>
          </>
        )}

        {success && (
          <div className="spanSuccess">
            <p>Password has been changed.</p>{" "}
            <div className="loginForgot">
              <Link to="/login" className="backToLogin-link" style={{ textDecoration: "none" }}>
                Login
              </Link>
            </div>
          </div>
        )}
        {keyValid && !success && (
          <Formik
            initialValues={{ password: "" }}
            validationSchema={dataSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleSubmit(values);
              setTimeout(() => {
                setSubmitting(false);
              }, 5000);
            }}
          >
            {({ submitForm, isSubmitting }) => (
              <Form className="loginBox">
                <Field
                  component={TextField}
                  sx={{ mt: 2 }}
                  fullWidth
                  //required
                  type="password"
                  name="password"
                  label="Password"
                  autoComplete="off"
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  sx={{ mt: 2 }}
                  fullWidth
                  //required
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password "
                  autoComplete="off"
                  variant="outlined"
                />

                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3 }}
                  type="submit"
                  onClick={submitForm}
                  disabled={isSubmitting}
                >
                  Set New Password
                </Button>
              </Form>
            )}
          </Formik>
        )}
        <span className="loginCopyright">Copyright © {year} Bamboo Strategic Media.</span>
      </div>
    </div>
  );
}
