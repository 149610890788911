import { useContext, useEffect, useState } from "react";
import { AppCacheContext } from "../context/appCacheContext";
import { IPaginate } from "../models/list";
import { useAppSelector } from "./hooks";

export function useFetch<
  T extends { _id?: string; id?: string; name?: string; email?: string; index?: number },
>(
  initUrl: string,
  options: {
    _id?: string;
    initPaginate?: IPaginate;
    cache?: boolean;
    dontLoad?: boolean;
    transformFunction?: (d: any) => any;
  } = {
    _id: undefined,
    initPaginate: { totalItems: 0, perPage: 1000, totalPages: 0, currentPage: 1, search: "" },
  },
) {
  const { _id, initPaginate, cache, dontLoad, transformFunction } = { ...options };

  const { authData } = useAppSelector((state) => state);
  const [item, setItem] = useState<Partial<T>>({});
  const [items, setItems] = useState<T[]>([]);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);

  //console.log(initUrl, loading);
  const [url, setUrl] = useState(initUrl);
  const keyForCache = JSON.stringify(initUrl);
  const { caches, setCaches, getCaches, clearCaches } = useContext(AppCacheContext);
  const [paginate, setPaginate] = useState<IPaginate>(
    initPaginate || {
      totalItems: 0,
      perPage: 1000,
      totalPages: 0,
      currentPage: 1,
      search: "",
    },
  );

  useEffect(() => {
    if (initUrl !== url) {
      //console.log(initUrl, ">>", url);
      setItems([]);
      //  change list (dataset to categories  this si to reload items)
      setUrl(initUrl);
      setPaginate(
        initPaginate || {
          totalItems: 0,
          perPage: 1000,
          totalPages: 0,
          currentPage: 1,
          search: "",
        },
      );
    }
    return () => {
      setLoading(true);
    };
    //eslint-disable-next-line
  }, [initUrl]);

  const headers = {
    "x-access-token": "",
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (authData.user) {
    headers["x-access-token"] = `Bearer ${authData.user.token}`;
  }
  //console.log(initUrl, caches);
  const fetchItems = async (options: { notloading?: boolean } = {}) => {
    const { notloading } = options;
    //console.log(initUrl); //debug
    if (!notloading) {
      setLoading(true);
    }

    let uri = url;
    if (cache) {
      const path = JSON.stringify(paginate);
      const data = getCaches(keyForCache, path);

      if (data && data.items) {
        if (data.items.length > 0 && items.length === 0) {
          setItems(data.items);
        }

        setPaginate(data.paginate);
        setError("");
        setTimeout(() => {
          setLoading(false);
        }, 100);

        return;
      }
    }

    if (paginate && typeof paginate === "object") {
      uri += "?";
      uri += `page=${paginate.currentPage}`;
      uri += `&perPage=${paginate.perPage}`;
      uri += `&search=${paginate.search}`;
      uri += `&queryString=${JSON.stringify(paginate.query || {})}`;
    }
    try {
      const response: { items: T[]; totalItems: number } = await (
        await fetch(uri, { headers: headers })
      ).json();
      const newPaginate = { ...paginate, totalItems: response.totalItems, loaded: true };
      const responseItems = response.items.map((d, i) => {
        //d.index = i + 1;
        if (!d.index) {
          d.index = 0;
        }
        d.id = d._id;
        if (initUrl.indexOf("/endpoints") === -1) {
          d.id = (i + 1 + paginate.perPage * (paginate.currentPage - 1)).toString();
        }
        //console.log(initUrl.indexOf("/endpoints"));
        //d.id = i + 1 + paginate.perPage * (paginate.currentPage - 1);
        if (!d.name && d.email) {
          d.name = d.email;
        }
        return d;
      });
      //console.log("paginate", newPaginate);
      const dataForList = transformFunction ? responseItems.map(transformFunction) : responseItems;
      setItems(dataForList);
      setPaginate(newPaginate);

      setError("");
      if (cache) {
        const path = JSON.stringify(paginate);
        setCaches(keyForCache, path, { paginate: newPaginate, items: responseItems });
      }
    } catch (err: any) {
      console.log(err);
      if (err && err.data && err.data[0]) {
        setError(err.data[0]);
      } else {
        setError("some error occurred");
      }
      setTimeout(() => setError(""), 5000);
    }
    setTimeout(() => {
      setLoading(false);
    }, 250);
  };
  const fetchItem = async (options: { id?: string; notloading?: boolean } = {}) => {
    const { id, notloading } = options;
    //console.log(initUrl); //debug
    if (!notloading) {
      setLoading(true);
    }

    try {
      const currentId = id ? id : _id;
      const uri = `${url}/${currentId}`;
      const response: T & { statusCode: number; message: string } = await (
        await fetch(uri, { headers: headers })
      ).json();
      //console.log(response);

      if (response.statusCode && response.statusCode === 500) {
        throw new Error(response.message);
      }
      setItem(response);
      setError("");
    } catch (err: any) {
      console.log(err);
      if (err && err.data && err.data.message) {
        setError(err.data.message);
      } else if (err && err.message) {
        setError(err.message);
      } else {
        setError("some error occurred");
      }
      setTimeout(() => setError(""), 8000);
    }
    setLoading(false);
  };
  const addItem = async (newItem: Partial<T>) => {
    setLoading(true);
    try {
      const data = await fetch(url, {
        headers: headers,
        method: "POST",
        body: JSON.stringify(newItem),
      });
      //console.log(data);
      if (data.status !== 200 && data.status !== 201) {
        throw await data.json();
      }
      setSuccess("Created successfully");
      setTimeout(() => setSuccess(""), 3000);
      if (caches[keyForCache]) {
        clearCaches(keyForCache);
      }
      return await data.json();
    } catch (err: any) {
      console.log(err);
      if (err && err[0]) {
        setError(err[0]);
      } else if (err && err.message) {
        setError(err.message);
      } else {
        setError("some error occurred");
      }
      setTimeout(() => setError(""), 8000);
    }
    setLoading(false);
  };
  const cloneItem = async (cloneId: string) => {
    setLoading(true);
    try {
      const uri = `${url}/${cloneId}`;
      const data = await fetch(uri, {
        headers: headers,
        method: "POST",
      });
      console.log(data);
      if (data.status !== 200 && data.status !== 201) {
        throw await data.json();
      }
      setSuccess("Cloned successfully");
      setTimeout(() => setSuccess(""), 3000);
      if (caches[keyForCache]) {
        clearCaches(keyForCache);
      }
      return await data.json();
    } catch (err: any) {
      console.log(err);
      if (err && err[0]) {
        setError(err[0]);
      } else if (err && err.message) {
        setError(err.message);
      } else {
        setError("some error occurred");
      }
      setTimeout(() => setError(""), 8000);
    }
    setLoading(false);
  };
  const reorderItems = async (id: string, body: any) => {
    setLoading(true);
    try {
      let uri = `${url}/change-index/${id}`;

      if (paginate && typeof paginate === "object") {
        uri += "?";
        uri += `page=${paginate.currentPage}`;
        uri += `&perPage=${paginate.perPage}`;
        uri += `&search=${paginate.search}`;
        uri += `&queryString=${JSON.stringify(paginate.query || {})}`;
      }

      const data = await fetch(uri, {
        headers: headers,
        method: "PUT",
        body: JSON.stringify(body),
      });

      if (data.status !== 200 && data.status !== 201) {
        throw await data.json();
      }

      const response: { items: T[] } = await data.json();
      console.log("response", response);
      const responseItems = response.items.map((d, i) => {
        d.id = d._id;
        //d.id = i + 1 + paginate.perPage * (paginate.currentPage - 1);
        if (!d.name && d.email) {
          d.name = d.email;
        }
        return d;
      });

      setItems(responseItems);
      setSuccess("Reirdered successfully");
      setTimeout(() => setSuccess(""), 3000);
      if (caches[keyForCache]) {
        clearCaches(keyForCache);
      }

      setError("");
    } catch (err: any) {
      console.log(err);
      if (err && err[0]) {
        setError(err[0]);
      } else if (err && err.message) {
        setError(err.message);
      } else {
        setError("some error occurred");
      }
      setTimeout(() => setError(""), 8000);
    }
    setLoading(false);
  };
  const updateItem = async (itemForUpdate: Partial<T>) => {
    //console.log("updateItem", itemForUpdate);
    const uri = `${url}/${itemForUpdate._id}`;
    try {
      const res = await fetch(uri, {
        headers: headers,
        method: "PUT",
        body: JSON.stringify(itemForUpdate),
      });

      if (res.status !== 200 && res.status !== 201) {
        const error = await res.json();
        console.log(error);
        throw error;
      }
      const result = await res.json();
      setSuccess("Updated successfully");
      setTimeout(() => setSuccess(""), 3000);
      if (caches[keyForCache]) {
        clearCaches(keyForCache);
      }

      return result;
      // try {

      // } catch (error) {
      //   console.log(error);
      // }
    } catch (err: any) {
      if (err && err[0]) {
        setError(err[0]);
      } else if (err && err.message) {
        setError(err.message);
      } else {
        setError("some error occurred");
      }
      setTimeout(() => setError(""), 8000);
    }
  };
  const deleteItem = async (id?: string) => {
    const uri = id ? `${url}/${id}` : `${url}/${_id}`;
    try {
      const res = await fetch(uri, {
        headers: headers,
        method: "DELETE",
      });
      //console.log(res);
      if (res.status !== 200) {
        throw await res.json();
      }
      setSuccess("Updated successfully");
      setTimeout(() => setSuccess(""), 3000);
      //console.log(caches[keyForCache]);
      if (caches[keyForCache]) {
        clearCaches(keyForCache);
      }
    } catch (err: any) {
      console.log(err);
      if (err && err.message) {
        setError(err.message);
      } else if (err && err[0]) {
        setError(err[0]);
      } else {
        setError("some error occurred");
      }

      setTimeout(() => setError(""), 8000);
      return err;
    }
  };

  useEffect(() => {
    if (!_id && paginate && typeof paginate === "object" && !paginate.loaded && !dontLoad) {
      fetchItems();
    }
    //eslint-disable-next-line
  }, [paginate]);

  return {
    items,
    error,
    loading,
    setLoading,
    success,
    setSuccess,
    setError,
    deleteItem,
    fetchItems,
    updateItem,
    fetchItem,
    item,
    setItem,
    addItem,
    paginate,
    setPaginate,
    setItems,
    cloneItem,
    reorderItems,
  };
}
