import { Box, IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { IItemTab, IParamsForColums } from "../../models/list";

import UserPageContent from "./UserPageContent";
import { Check, DeleteOutline } from "@mui/icons-material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

export const userColumns = ({ handleActionItem }: IParamsForColums) => {
  const colums = [
    {
      field: "id",
      headerName: "№",
      width: 20,
    },

    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              whiteSpace: "normal",
              color: "primary.main",
            }}
          >
            <Link to={`/users/${params.row?._id}?tab=0`} className="break-all">
              {params.row?.email}
            </Link>
          </Box>
        );
      },
    },
    {
      field: "action",
      width: 95,
      headerName: "Action",
      renderCell: (params: any) => {
        return (
          <div className="cellAction">
            <Link to={`/users/${params.row._id}?tab=0`}>
              <Tooltip title="Edit">
                <IconButton>
                  <CreateOutlinedIcon className="icon text-green-500" />
                </IconButton>
              </Tooltip>
            </Link>

            <div
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                handleActionItem(params.row._id, "Delete");
              }}
            >
              <Tooltip title="Delete">
                <IconButton>
                  <DeleteOutline className="deleteIcon" />
                </IconButton>
              </Tooltip>
            </div>
            {params.row.success && (
              <div>
                <Check className="okicon" />
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return colums;
};

export const initPaginateUser = {
  totalItems: 0,
  perPage: 25,
  totalPages: 0,
  currentPage: 1,
  search: "",
};

export const userTabsData: IItemTab[] = [{ label: "Content", component: [UserPageContent] }];
