import { createSlice } from "@reduxjs/toolkit";
export const actionResultSlice = createSlice({
  name: "actionResult",
  initialState: "",
  reducers: {
    actionResultSuccess: (state) => {
      state = "success";
      return state;
    },
    actionResultError: (state) => {
      state = "error";
      return state;
    },
    actionResultClear: (state) => {
      state = "";
      return state;
    },
  },
});

export const actionResultActions = actionResultSlice.actions;

export const { actionResultSuccess, actionResultError, actionResultClear } =
  actionResultSlice.actions;
export default actionResultSlice.reducer;
