import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IUser } from "../../models/user";
import { ServerResponse } from "../../models/models";
import { RootState } from "../index";

export const userApi = createApi({
  reducerPath: "users/api",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/users",
    prepareHeaders: (headers, { getState }) => {
      const user = (getState() as RootState).authData.user;

      //If we have a token set in state, let's assume that we should be passing it.
      if (user && user.token) {
        headers.set("x-access-token", `Bearer ${user.token}`);
      }
      return headers;
    },
  }),
  refetchOnFocus: true,
  tagTypes: ["Users"],
  endpoints: (build) => ({
    getUsers: build.query<
      ServerResponse<IUser>,
      { search?: string; currentPage?: number; perPage?: number; condition?: string }
    >({
      query: ({ search, currentPage, perPage, condition }) => ({
        url: ``,
        params: {
          search: search,
          perPage: perPage,
          page: currentPage,
          condition: condition,
        },
      }),
      providesTags: ["Users"],
      transformResponse: (response: ServerResponse<IUser>) => {
        if (response && response.items && response.items.length > 0) {
          response.items = response.items.map((d, i) => {
            //d.id = i + 1 + response.perPage * (response.currentPage - 1);
            return d;
          });
        }
        return response;
      },
    }),
    getUser: build.query<IUser, string | undefined>({
      query: (_id) => {
        return {
          url: `/${_id}`,
        };
      },
    }),
    addUser: build.mutation<IUser, Partial<IUser>>({
      query(body: Partial<IUser>) {
        return {
          url: ``,
          method: "POST",
          body,
        };
      },
      // Invalidates all Post-type queries providing the `LIST` id - after all, depending of the sort order,
      // that newly created post could show up in any lists.
      //invalidatesTags: [{ type: 'Post', id: 'LIST' }],
      invalidatesTags: ["Users"],
    }),
    updateUser: build.mutation<IUser, Partial<IUser>>({
      query(data) {
        const { _id, id, ...body } = data;
        return {
          url: `/${_id}`,
          method: "PUT",
          body,
        };
      },

      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under its results.
      invalidatesTags: ["Users"],

      //invalidatesTags: (result, error, { _id }) => [{ type: "Users", _id }],
    }),
    deleteUser: build.mutation<{ success: boolean; _id: string }, string>({
      query(_id) {
        return {
          url: `/${_id}`,
          method: "DELETE",
        };
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      //invalidatesTags: (result, error, _id) => [{ type: "Users", _id }],
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetUserQuery,
} = userApi;
