import "./sidebar.scss";
import { useContext, useEffect, useRef, useState } from "react";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Brightness4OutlinedIcon from "@mui/icons-material/Brightness4Outlined";
import Brightness7OutlinedIcon from "@mui/icons-material/Brightness7Outlined";
import TranslateIcon from "@mui/icons-material/Translate";
import SettingsIcon from "@mui/icons-material/Settings";

import Divider from "@mui/material/Divider";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import useWindowDimensions from "../../hooks/windowDimensions";

import { useActions } from "../../hooks/actions";
import { useAppSelector } from "../../hooks/hooks";
import { ExitToAppOutlined } from "@mui/icons-material";

import { backToAdmin } from "../../store/auth/auth.slice";
import { useAppDispatch } from "../../hooks/hooks";
import appData from "../../appdata.json";

import { AppCacheContext } from "src/context/appCacheContext";
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { AppGlobalContext } from "src/context/appGlobalContext";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { toggleSideBar, loguotAuth, toggleDarkMode } = useActions();
  const { sideBarClose, authData, darkMode } = useAppSelector((state) => state);
  const { TITLE, VERSION } = appData as any;
  // eslint-disable-next-line
  const [enabledScroll, setEnabledScroll] = useState<boolean>(false);

  const { windowWidth } = useWindowDimensions();
  const { toggleParangaForViewportTrue } = useContext(AppGlobalContext);
  const { clearCaches } = useContext(AppCacheContext);
  const wrapper = useRef<HTMLDivElement>(null);

  const handleToggleMode = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    toggleDarkMode();
  };
  const handleToggleSideBar = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    toggleSideBar();
  };
  const hadleBackToAdmin = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    toggleParangaForViewportTrue();
    clearCaches();
    dispatch(backToAdmin());
    navigate("/");
    window.location.reload();

    // navigate("/");
    // window.location.reload();
  };

  useEffect(() => {
    if (wrapper.current) {
      if (wrapper.current?.clientHeight < wrapper.current?.scrollHeight) {
        setEnabledScroll(true);
      }
    }
  }, [wrapper, sideBarClose]);

  return (
    <>
      {windowWidth < 800 && !sideBarClose && (
        <Box
          sx={{
            position: "fixed",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            inset: " 0px",
            backgroundColor: "action.disabledBackground",
            zIndex: 11,
            top: 0,
            left: 0,
          }}
          onClick={handleToggleSideBar}
        ></Box>
      )}

      <Box
        className="sidebar"
        sx={{
          width: windowWidth < 800 ? "240px" : "140px",
          position: "fixed",
          top: windowWidth < 800 ? 0 : "50px",
          zIndex: 12,
          transform: !sideBarClose
            ? `translateX(0px)`
            : windowWidth < 800
            ? `translateX(-240px)`
            : `translateX(-90px)`,
          transition: "transform 0.15s ease",
          borderRight: "0.5px solid",
          borderRightColor: "action.disabledBackground",
          minHeight: windowWidth < 800 ? "100vh" : "calc(100vh - 50px)",
          maxHeight: windowWidth < 800 ? "100vh" : "calc(100vh - 50px)",
          backgroundColor: "background.default",
        }}
      >
        {windowWidth < 800 && (
          <Box
            sx={{
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "0.5px solid",
              borderBottomColor: "divider",
            }}
          >
            <Box sx={{ pl: "15px" }}>
              {TITLE} {VERSION}
            </Box>
            <IconButton onClick={handleToggleSideBar}>
              <MenuIcon />
            </IconButton>
          </Box>
        )}

        <div className="center" ref={wrapper}>
          {windowWidth < 800 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "0.5px solid",
                borderBottomColor: "divider",
                py: 1,
              }}
            >
              <Box
                sx={{
                  mx: 2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  //color: "text.secondary",
                  maxWidth: 240,
                }}
              >
                {authData.user?.email}
              </Box>
            </Box>
          )}

          <List sx={{ pt: "4px" }}>
            <ListItem disablePadding component={Link} to="/translationlogs">
              <ListItemButton
                sx={{ pl: 2, pr: sideBarClose ? "14px" : 1, py: 0 }}
                selected={location.pathname.indexOf("/translationlogs") > -1}
              >
                <ListItemText
                  primary="Translation logs"
                  primaryTypographyProps={{
                    fontSize: "13px",
                    fontWeight: 600,
                    fontFamily: "inherit",
                    sx: {
                      transform: sideBarClose ? "translateX(-10px)" : "translateX(0px)",
                    },
                  }}
                />
                <TranslateIcon color="primary" sx={{ height: "17px" }} />
              </ListItemButton>
            </ListItem>

            {authData.user &&
              authData.user.roles &&
              authData.user.roles.some(
                (r: any) => r.role === "SuperAdmin" || r.role === "Admin",
              ) && (
                <>
                  <ListItem disablePadding component={Link} to="/users">
                    <ListItemButton
                      sx={{ pl: 2, pr: sideBarClose ? "14px" : 1, py: 0 }}
                      selected={location.pathname.indexOf("/users") > -1}
                    >
                      <ListItemText
                        primary="Users"
                        primaryTypographyProps={{
                          fontSize: "13px",
                          fontWeight: 600,
                          fontFamily: "inherit",
                        }}
                      />
                      <PersonOutlineIcon color="primary" sx={{ height: "17px" }} />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding component={Link} to="/settings">
                    <ListItemButton
                      sx={{ pl: 2, pr: sideBarClose ? "14px" : 1, py: 0 }}
                      selected={location.pathname.indexOf("/settings") > -1}
                    >
                      <ListItemText
                        primary="Settings"
                        primaryTypographyProps={{
                          fontSize: "13px",
                          fontWeight: 600,
                          fontFamily: "inherit",
                        }}
                      />
                      <SettingsIcon color="primary" sx={{ height: "17px" }} />
                    </ListItemButton>
                  </ListItem>
                </>
              )}

            <Divider sx={{ my: "4px", borderBottomWidth: "1px" }} />
            {authData.user && authData.user.fromAdmin && (
              <ListItem disablePadding onClick={hadleBackToAdmin}>
                <ListItemButton sx={{ pl: 2, pr: sideBarClose ? "14px" : 1, py: 0 }}>
                  <ListItemText
                    primary="Back to admin"
                    primaryTypographyProps={{
                      fontSize: "13px",
                      fontWeight: 600,
                      fontFamily: "inherit",
                      sx: {
                        transform: sideBarClose ? "translateX(-10px)" : "translateX(0px)",
                      },
                    }}
                  />
                  <ExitToAppOutlined color="primary" sx={{ height: "17px" }} />
                </ListItemButton>
              </ListItem>
            )}
            {authData.user && authData.user.fromSuperAdmin && (
              <ListItem disablePadding onClick={hadleBackToAdmin}>
                <ListItemButton sx={{ pl: 2, pr: sideBarClose ? "14px" : 1, py: 0 }}>
                  <ListItemText
                    primary="Back to super admin"
                    primaryTypographyProps={{
                      fontSize: "13px",
                      fontWeight: 600,
                      fontFamily: "inherit",
                      sx: {
                        transform: sideBarClose ? "translateX(-10px)" : "translateX(0px)",
                      },
                    }}
                  />
                  <ExitToAppOutlined color="primary" sx={{ height: "17px" }} />
                </ListItemButton>
              </ListItem>
            )}
            {authData.user && (
              <ListItem disablePadding onClick={() => loguotAuth()}>
                <ListItemButton sx={{ pl: 2, pr: sideBarClose ? "14px" : 1, py: 0 }}>
                  <ListItemText
                    primary="Logout"
                    primaryTypographyProps={{
                      fontSize: "13px",
                      fontWeight: 600,
                      fontFamily: "inherit",
                    }}
                  />
                  <ExitToAppIcon color="primary" sx={{ height: "17px" }} />
                </ListItemButton>
              </ListItem>
            )}
            <Divider sx={{ my: "4px", borderBottomWidth: "1px" }} />
            <ListItem disablePadding onClick={handleToggleMode}>
              {darkMode === "dark" ? (
                <ListItemButton sx={{ pl: 2, pr: sideBarClose ? "14px" : 1, py: 0 }}>
                  <ListItemText
                    primary="Dark"
                    primaryTypographyProps={{
                      fontSize: "13px",
                      fontWeight: 600,
                      fontFamily: "inherit",
                    }}
                  />
                  <Brightness7OutlinedIcon color="primary" sx={{ height: "17px" }} />
                </ListItemButton>
              ) : (
                <ListItemButton sx={{ pl: 2, pr: sideBarClose ? "14px" : 1, py: 0 }}>
                  <ListItemText
                    primary="Light"
                    primaryTypographyProps={{
                      fontSize: "13px",
                      fontWeight: 600,
                      fontFamily: "inherit",
                    }}
                  />
                  <Brightness4OutlinedIcon color="primary" sx={{ height: "17px" }} />
                </ListItemButton>
              )}
            </ListItem>
          </List>
        </div>
      </Box>
    </>
  );
};

export default Sidebar;
