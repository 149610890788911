import * as React from "react";
import { useAppSelector } from "../hooks/hooks";
//import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import "./infoPopper.scss";
import {
  Box,
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
  styled,
  Tooltip,
} from "@mui/material";

interface Props {
  text: string;

  placement?: PopperPlacementType;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  // You can replace with `PopperUnstyled` for lower bundle size.
  zIndex: 1,

  '&[data-popper-placement*="bottom"] .arrow': {
    top: 0,
    left: 0,
    marginTop: "-0.9em",
    width: "3em",
    height: "1em",
    "&::before": {
      borderWidth: "0 1em 1em 1em",
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
  '&[data-popper-placement*="top"] .arrow': {
    bottom: 0,
    left: 0,
    marginBottom: "-2.9em",
    width: "5em",
    height: "3em",
    "&::before": {
      borderWidth: "2em 2em 1em 2em",
      borderColor: `${
        theme.palette.mode === "dark" ? "#2c2c2c" : theme.palette.background.paper
      } transparent transparent transparent`,
    },
  },
  '&[data-popper-placement*="right"] .arrow': {
    left: 0,
    marginLeft: "-0.9em",
    height: "3em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 1em 1em 0",
      //borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
    },
  },
  '&[data-popper-placement*="left"] .arrow': {
    right: 0,
    marginRight: "-0.9em",
    height: "3em",
    width: "1em",
    "&::before": {
      borderWidth: "1em 0 1em 1em",
      //borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    },
  },
}));

const styles = {
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
};

const InfoPopper = ({ text, placement = "top" }: Props) => {
  const { darkMode } = useAppSelector((state) => state);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setTimeout(() => {
      (document.activeElement as HTMLElement).blur();
    }, 50);
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  return (
    <div className="infoPopper">
      <Tooltip title="Notes">
        <IconButton onClick={handleClick}>
          <StickyNote2OutlinedIcon color="action" />
        </IconButton>
      </Tooltip>

      <StyledPopper
        sx={{ zIndex: 2000 }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top"
        className={darkMode ? "darkMode" : ""}
        transition
        //className={darkMode ? "bg-gray-700 text-white" : "bg-white text-gray-700"}
        modifiers={[
          // {
          //   name: "flip",
          //   enabled: true,
          //   options: {
          //     altBoundary: true,
          //     rootBoundary: "viewport",
          //     padding: 8,
          //   },
          // },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: false,
              altBoundary: false,
              tether: false,
              rootBoundary: "document",
              padding: 14,
            },
          },
          // {
          //   name: "preventOverflow",
          //   enabled: true,
          //   options: {
          //     altAxis: true,
          //     altBoundary: true,
          //     tether: true,
          //     rootBoundary: "viewport",
          //     padding: 8,
          //   },
          // },

          // {
          //   name: "offset",
          //   enabled: true,
          //   options: {
          //     offset: [0, 0],
          //   },
          // },

          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={150}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setOpen(false);
                }}
              >
                <Paper
                  elevation={5}
                  className="popperItem"
                  sx={{
                    maxWidth: 365,
                    padding: 2,

                    maxHeight: 250,
                    overflowY: "hide",
                    whiteSpace: "normal",
                  }}
                >
                  <Box component="span" className="arrow" ref={setArrowRef} sx={styles.arrow} />
                  <Box>
                    <div
                      className="content max-w-lg break-all"
                      dangerouslySetInnerHTML={{ __html: text }}
                    ></div>
                  </Box>
                </Paper>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </StyledPopper>
    </div>
  );
};

export default InfoPopper;
